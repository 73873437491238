import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import {
  ICreateOrEditVendorData,
  IVendorsListQueryData,
} from "../../types/vendor";

/**
 * @description Dispatch this thunk to create vendor
 * @param {ICreateOrEditVendorData} bodyData - Object containing information for creating new vendor
 */
export const createVendorThunk = createAsyncThunk(
  "vendors/createVendorThunk",
  async (bodyData: ICreateOrEditVendorData, { rejectWithValue }) => {
    try {
      // await register(bodyData);
      const { data } = await API.post(API_ROUTES.VENDORS.CREATE, {
        ...bodyData,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get list of vendors
 * @param {IVendorsListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllVendorsThunk = createAsyncThunk(
  "vendors/getAllVendorsThunk",
  async (queryData: IVendorsListQueryData | undefined, { rejectWithValue }) => {
    const { limit = 30, page = 1 } = queryData ?? {};

    try {
      const { data } = await API.get(API_ROUTES.VENDORS.GET_LIST(limit, page));
      return data?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
/**
 * @description Dispatch this thunk to get vendor by id
 * @param {string} id - String id to find current vendor
 */
export const getVendorByIdThunk = createAsyncThunk(
  "vendors/getVendorByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.VENDORS.GET_BY_ID(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get vendor by id
 * @param {ICreateOrEditVendorData} values -an Object containing information for updating vendor
 */
export const updateVendorThunk = createAsyncThunk(
  "vendors/updateVendorThunk",
  async (
    values: ICreateOrEditVendorData,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await API.patch(
        API_ROUTES.VENDORS.UPDATE(values.id?.toString() || ""),
        {
          ...values,
        },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to delete vendor by id
 * @param {string} id - String id to find current vendor for deleting
 */
export const deleteVendorThunk = createAsyncThunk(
  "vendors/deleteVendorThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.VENDORS.DELETE(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
