import { createAsyncThunk } from "@reduxjs/toolkit";
import { IShowNotification } from "../../types/auth";

/**
 * @description Dispatch this thunk to show a notification.
 */
export const showNotificationThunk = createAsyncThunk<
  IShowNotification,
  IShowNotification
>("alert/showNotificationThunk", async (notification, { rejectWithValue }) => {
  try {
    return notification;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const hideNotificationThunk = createAsyncThunk(
  "alert/hideNotificationThunk",
  async (_, { rejectWithValue }) => {
    return "success";
  },
);
