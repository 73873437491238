import { createSlice } from "@reduxjs/toolkit";
import {
  IResetPassword,
  IStoreStatuses,
  STATUS_ENUM,
} from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import {
  changePasswordThunk,
  checkLoggedUserThunk,
  logInThunk,
  logoutThunk,
  resetPasswordRequestThunk,
  resetPasswordThunk,
} from "./thunks";
import { LoginResponseType } from "../../helpers/constants";
import { IAuthActionRequired } from "../../types/auth";
import { User } from "../../types/users";

interface IState {
  user: User | null;
  email: string | null;
  statuses: IStoreStatuses;
  resetPasswordInfo: IResetPassword | null;
}

const initialState: IState = {
  user: null,
  email: null,
  resetPasswordInfo: null,
  statuses: StatusGenerator.generateStatuses([
    logInThunk.typePrefix,
    logoutThunk.typePrefix,
    resetPasswordThunk.typePrefix,
    changePasswordThunk.typePrefix,
    checkLoggedUserThunk.typePrefix,
  ]),
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetStatus: (state) => {
      state.statuses = initialState.statuses;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logInThunk.pending, (state) => {
        state.statuses[logInThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.PENDING,
        );
      })
      .addCase(logInThunk.rejected, (state, { error }) => {
        state.statuses[logInThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.REJECTED,
          error.message,
        );
      })
      .addCase(logInThunk.fulfilled, (state, { payload }) => {
        let action;
        switch (payload.type) {
          case LoginResponseType.SUCCESS:
            state.user = payload.payload as User;
            break;
          case LoginResponseType.ACTION_REQUIRED:
            const actionContainer = payload?.payload as IAuthActionRequired;
            action = actionContainer.action;
            state.email = actionContainer.payload?.email;
            break;
        }
        state.statuses[logInThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.FULFILLED,
          undefined,
          action,
        );
      })
      .addCase(changePasswordThunk.pending, (state) => {
        state.statuses[changePasswordThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(changePasswordThunk.rejected, (state, { payload }) => {
        state.statuses[changePasswordThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, payload as string);
      })
      .addCase(changePasswordThunk.fulfilled, (state) => {
        state.statuses[changePasswordThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      })
      .addCase(resetPasswordThunk.pending, (state) => {
        state.statuses[resetPasswordThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(resetPasswordThunk.rejected, (state, { payload }) => {
        state.statuses[resetPasswordThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, payload as string);
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.statuses[resetPasswordThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.resetPasswordInfo = null;
      })
      .addCase(resetPasswordRequestThunk.pending, (state) => {
        state.statuses[resetPasswordRequestThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(resetPasswordRequestThunk.rejected, (state, { payload }) => {
        state.statuses[resetPasswordRequestThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, payload as string);
      })
      .addCase(resetPasswordRequestThunk.fulfilled, (state) => {
        state.statuses[resetPasswordRequestThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      })
      .addCase(checkLoggedUserThunk.rejected, (state, { error }) => {
        state.statuses[checkLoggedUserThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
        state.user = null;
      })
      .addCase(checkLoggedUserThunk.fulfilled, (state, { payload }) => {
        state.statuses[checkLoggedUserThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.user = payload;
      })
      .addCase(logoutThunk.pending, (state) => {
        state.statuses[logoutThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.PENDING,
        );
      })
      .addCase(logoutThunk.fulfilled, (state) => {
        state.statuses[logoutThunk.typePrefix] = StatusGenerator.setStatus(
          STATUS_ENUM.FULFILLED,
        );
        state.user = null;
      }),
});

export default authSlice;
