import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PlusOne from "@mui/icons-material/PlusOne";
import { companiesSelector } from "../../../redux/companies/selectors";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  NotificationSeverity,
  TABLE_ACTIONS,
} from "../../../helpers/constants";
import Container from "../../../components/Container";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  deleteCompanyThunk,
  getAllCompaniesThunk,
} from "../../../redux/companies/thunks";
import ConfirmActionModal from "../../../modals/ConfirmActionModal/ConfirmActionModal";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import CreateCompanyModal from "../../../modals/CreateCompanyModal/CreateCompanyModal";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/Table/CustomTable";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

export default function CompaniesList() {
  const dispatch = useAppDispatch();
  const [createCompanyModal, setCreateCompanyModal] = useState(false);
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false);
  const [companyIdToDelete, setCompanyIdToDelete] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCompaniesThunk());
  }, [dispatch]);

  const companies = useAppSelector(companiesSelector);

  const handleActionClick = (action: TABLE_ACTIONS, id: string) => {
    switch (action) {
      case TABLE_ACTIONS.EDIT:
        const companyToEdit = companies?.find((company) => company.id === id);
        if (companyToEdit) {
          navigate(`/company/edit/${id}`);
        }
        break;
      case TABLE_ACTIONS.DELETE:
        setCompanyIdToDelete(id);
        setDeleteCompanyModal(true);
        break;
      case TABLE_ACTIONS.QUOTATIONS:
        const selectedCompany = companies?.find((company) => company.id === id);
        if (selectedCompany) {
          navigate(`/quotation/${id}/list`);
        }
        break;
      default:
        break;
    }
  };

  const handleCreateCompanyModalOpen = () => {
    setCreateCompanyModal(true);
  };

  const handleCreateCompanyModalDismiss = () => {
    setCreateCompanyModal(false);
  };

  const handleCreateCompany = () => {
    dispatch(getAllCompaniesThunk());
  };

  const handleDelete = async () => {
    if (companyIdToDelete === "") {
      return;
    }
    const result = await dispatch(deleteCompanyThunk(companyIdToDelete));
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );

    dispatch(getAllCompaniesThunk());
    setCompanyIdToDelete("");
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Companies</Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCreateCompanyModalOpen()}
          startIcon={<PlusOne />}
        >
          New Company
        </Button>
      </Stack>

      <CustomTable
        entities={companies}
        handleActionClick={handleActionClick}
        fieldToFilter={"name"}
        columns={[
          {
            id: "name",
            label: "Name",
            value: "name",
          },
          {
            id: "companyDescription",
            label: "Description",
            value: "companyDescription",
          },
          { id: "actions", value: "" },
        ]}
        isSelectable={false}
        allowedMenuItems={[
          TABLE_ACTIONS.EDIT,
          TABLE_ACTIONS.DELETE,
          TABLE_ACTIONS.QUOTATIONS,
        ]}
        extraActionItems={[
          {
            name: TABLE_ACTIONS.QUOTATIONS,
            icon: <RequestQuoteIcon />,
          },
        ]}
      />

      {createCompanyModal && (
        <CreateCompanyModal
          handleCreateCompanyChange={handleCreateCompany}
          createCompanyModal={createCompanyModal}
          handleCreateCompanyModalDismiss={handleCreateCompanyModalDismiss}
        />
      )}
      {deleteCompanyModal && (
        <ConfirmActionModal
          title={"Heads up!"}
          message={"Are you sure you want to delete this company?"}
          show={deleteCompanyModal}
          onConfirm={handleDelete}
          onDismiss={() => {
            setDeleteCompanyModal(false);
          }}
        />
      )}
    </Container>
  );
}
