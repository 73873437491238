import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { StyledFormicError } from "../../../modals/ResetPasswordModal/styles";
import { Company } from "../../../types/company";
import {
  getCompanyByIdThunk,
  updateCompanyThunk,
} from "../../../redux/companies/thunks";
import Container from "../../../components/Container";
import Stack from "@mui/material/Stack";
import { Card, CardActions, CardContent } from "@mui/material";
import { ROLES, User } from "../../../types/users";
import { usersSelector } from "../../../redux/users/selectors";
import {
  getAllUsersThunk,
  updateUsersKeyThunk,
} from "../../../redux/users/thunks";
import { selectedCompanySelector } from "../../../redux/companies/selectors";
import { getValidationSchema } from "./utils";
import PlusOne from "@mui/icons-material/PlusOne";
import UserTable from "../../Users/list/components/UserTable";
import SelectEntitiesModal from "../../../modals/SelectEntitiesModal/SelectEntitiesModal";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { getAllVendorsThunk } from "../../../redux/vendors/thunks";
import { vendorsSelector } from "../../../redux/vendors/selectors";
import { Vendor } from "../../../types/vendor";
import VendorsTable from "../../Vendors/components/VendorsTable";
import {
  ENTITIES,
  Messages,
  NotificationSeverity,
} from "../../../helpers/constants";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import { userSelector } from "../../../redux/auth/selectors";

export default function EditCompany() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openAddExistingUserModal, setOpenAddExistingUserModal] =
    useState(false);
  const [openAddExistingVendorModal, setOpenAddExistingVendorModal] =
    useState(false);
  const [selectedUsers, setSelectedUsers] = useState([] as User[]);
  const [selectedVendors, setSelectedVendors] = useState([] as Vendor[]);
  const company = useAppSelector(selectedCompanySelector);
  const user = useAppSelector(userSelector);
  const { id } = params;

  useEffect(() => {
    dispatch(getAllUsersThunk());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllVendorsThunk());
  }, [dispatch]);

  useEffect(() => {
    setSelectedUsers(company?.users || []);
    setSelectedVendors(company?.vendors || []);
  }, [company]);

  const allManagers = useAppSelector(usersSelector)?.filter(
    (user) =>
      (user?.company?.id === id || !user?.company) &&
      [ROLES.MANAGER].includes(user.role as ROLES),
  );

  let allowedRoles: ROLES[] = [];
  switch (user?.role) {
    case ROLES.ADMIN:
      allowedRoles = [ROLES.MANAGER, ROLES.COORDINATOR];
      break;
    case ROLES.MANAGER:
      allowedRoles = [];
      break;
  }

  const allVendors = useAppSelector(vendorsSelector);

  useEffect(() => {
    dispatch(getCompanyByIdThunk(id || ""));
  }, [dispatch, id]);

  const handleCreateOrUpdateEntity = (
    entity: string,
    payload?: Vendor | User,
  ) => {
    switch (entity) {
      case ENTITIES.USERS:
        if (payload) {
          if (!selectedUsers.find((used) => used.id === payload.id)) {
            setSelectedUsers([...selectedUsers, payload as User]);
          } else {
            dispatch(getCompanyByIdThunk(id || ""));
          }
        }
        break;
      case ENTITIES.VENDORS:
        dispatch(getAllVendorsThunk());
        if (payload) {
          if (!selectedVendors.find((vendor) => vendor.id === payload.id)) {
            setSelectedVendors([...selectedVendors, payload as Vendor]);
          } else {
            dispatch(getCompanyByIdThunk(id || ""));
          }
        }
        break;
    }
  };

  const handleSelectedEntities = (
    selectedEntitiesIds: string[],
    entity: string,
  ) => {
    switch (entity) {
      case ENTITIES.USERS:
        setSelectedUsers(
          allManagers?.filter((user) =>
            selectedEntitiesIds.includes(user.id),
          ) || [],
        );
        break;
      case ENTITIES.VENDORS:
        setSelectedVendors(
          allVendors?.filter((vendor) =>
            selectedEntitiesIds.includes(vendor.id),
          ) || [],
        );
        break;
    }
  };

  const handleDelete = async (idToRemove: string, entity: string) => {
    switch (entity) {
      case ENTITIES.USERS:
        setSelectedUsers(
          selectedUsers.filter((user) => user.id !== idToRemove),
        );
        break;
      case ENTITIES.VENDORS:
        setSelectedVendors(
          selectedVendors.filter((vendor) => vendor.id !== idToRemove),
        );
        break;
    }
  };

  const handleSubmit = async (values: Company) => {
    setLoading(true);
    await dispatch(updateCompanyThunk(values));
    setLoading(false);
    dispatch(
      showNotificationThunk({
        message: Messages.successUpdateCompany,
        severity: NotificationSeverity.success,
      }),
    );
    await dispatch(
      updateUsersKeyThunk({
        users: selectedUsers,
        company: company as Company,
      }),
    );
    dispatch(getCompanyByIdThunk(id || ""));
  };

  const formik = useFormik({
    initialValues: {
      name: company?.name || "",
      companyDescription: company?.companyDescription || "",
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema,
    onSubmit: async (values) => {
      const data = {
        id: id || "",
        name: values.name || "",
        companyDescription: values.companyDescription,
        users: selectedUsers,
        vendors: selectedVendors,
      };
      await handleSubmit(data);
    },
  });

  return (
    <Container>
      <FormikProvider value={formik}>
        <Card sx={{}} component="form" onSubmit={formik.handleSubmit}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography color={"primary"} variant="h4">
                Edit Company
              </Typography>
            </Stack>

            <Box sx={{ mt: 1 }}>
              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                value={formik?.values?.name}
              />
              <ErrorMessage name="name" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                id="companyDescription"
                label="Description"
                name="companyDescription"
                autoComplete="companyDescription"
                autoFocus
                value={formik?.values?.companyDescription}
              />
              <ErrorMessage
                name="companyDescription"
                component={StyledFormicError}
              />
            </Box>
          </CardContent>
          <CardContent>
            <Box sx={{ mt: 3 }}>
              <UserTable
                title="Company Users"
                allowedRoles={allowedRoles}
                extraButtons={
                  user?.role === ROLES.ADMIN
                    ? [
                        {
                          title: "Add existing user",
                          color: "secondary",
                          icon: <PlusOne />,
                          handleClick: () => {
                            setOpenAddExistingUserModal(true);
                          },
                        },
                      ]
                    : []
                }
                users={selectedUsers}
                handleCreateOrUpdateUser={(user?: User) => {
                  handleCreateOrUpdateEntity(ENTITIES.USERS, user);
                }}
                handleDelete={(idToRemove) =>
                  handleDelete(idToRemove, ENTITIES.USERS)
                }
                company={company || undefined}
              />
            </Box>
          </CardContent>
          <CardContent>
            <Box sx={{ mt: 3 }}>
              <VendorsTable
                title="Company Vendors"
                extraButtons={
                  user?.role === ROLES.ADMIN
                    ? [
                        {
                          title: "Add existing vendor",
                          color: "secondary",
                          icon: <PlusOne />,
                          handleClick: () => {
                            setOpenAddExistingVendorModal(true);
                          },
                        },
                      ]
                    : []
                }
                vendors={selectedVendors}
                handleCreateOrUpdateVendor={(vendor?: Vendor) => {
                  handleCreateOrUpdateEntity(ENTITIES.VENDORS, vendor);
                }}
                handleDelete={(idToRemove) =>
                  handleDelete(idToRemove, ENTITIES.VENDORS)
                }
              />
            </Box>
          </CardContent>
          <CardActions
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                disabled={loading}
                color="primary"
                size="large"
                variant="contained"
                type="submit"
              >
                SAVE
              </Button>
              {loading && (
                <CircularProgress
                  size={30}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </CardActions>
        </Card>
      </FormikProvider>

      {openAddExistingUserModal && (
        <SelectEntitiesModal
          title={"Select the existing users to add"}
          show={openAddExistingUserModal}
          onDismiss={() => {
            setOpenAddExistingUserModal(false);
          }}
          onSelectDone={(selected: string[]) => {
            handleSelectedEntities(selected, ENTITIES.USERS);
          }}
          entitiesIdsAlreadySelected={selectedUsers.map((user) => user.id)}
          getEntities={() => allManagers || []}
          columns={[
            {
              id: "name",
              label: "Name",
              value: (row: any) => `${row.firstname} ${row.lastname}`,
            },
            {
              id: "email",
              label: "Email",
              value: "email",
            },
          ]}
          fieldToFilter={"name"}
        />
      )}

      {openAddExistingVendorModal && (
        <SelectEntitiesModal
          title={"Select the existing vendor to add"}
          show={openAddExistingVendorModal}
          onDismiss={() => {
            setOpenAddExistingVendorModal(false);
          }}
          onSelectDone={(selected: string[]) => {
            handleSelectedEntities(selected, ENTITIES.VENDORS);
          }}
          entitiesIdsAlreadySelected={selectedVendors.map(
            (vendor) => vendor.id,
          )}
          getEntities={() => allVendors || []}
          columns={[
            {
              id: "name",
              label: "Name",
              value: "name",
            },
            {
              id: "email",
              label: "Email",
              value: "email",
            },
          ]}
          fieldToFilter={"name"}
        />
      )}
    </Container>
  );
}
