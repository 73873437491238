/**
 * @description Object containing all request endpoints
 */
export const API_ROUTES = {
  USERS: {
    CREATE: "api/users/",
    GET_ADMIN: "api/users/admin/",
    GET_LIST: (limit: number, page: number) =>
      `api/users/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `api/users/${id}/`,
    GET_BY_ID: (id: string) => `api/users/${id}/`,
    GET_BY_EMAIL: (email: string) => `api/users/email/${email}/`,
    UPDATE: (id: string) => `api/users/${id}/`,
    UPDATE_KEY: "api/users/update/key",
  },
  COMPANIES: {
    CREATE: "api/company/",
    GET_LIST: (limit: number, page: number) =>
      `api/company/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `api/company/${id}/`,
    GET_BY_ID: (id: string) => `api/company/${id}/`,
    UPDATE: (id: string) => `api/company/${id}/`,
  },
  VENDORS: {
    CREATE: "api/vendor/",
    GET_LIST: (limit: number, page: number) =>
      `api/vendor/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `api/vendor/${id}/`,
    GET_BY_ID: (id: string) => `api/vendor/${id}/`,
    UPDATE: (id: string) => `api/vendor/${id}/`,
  },

  PARTNERS: {
    CREATE: "api/partner/",
    GET_LIST: (limit: number, page: number) =>
      `api/partner/?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `api/partner/${id}/`,
    GET_BY_ID: (id: string) => `api/partner/${id}/`,
    UPDATE: (id: string) => `api/partner/${id}/`,
  },

  QUOTATION: {
    CREATE: "api/quotation/",
    GET_LIST: (companyId: string, limit: number, page: number) =>
      `api/quotation/company/${companyId}?page=${page}&limit=${limit}`,
    DELETE: (id: string) => `api/quotation/${id}/`,
    GET_BY_ID: (id: string) => `api/quotation/${id}/`,
    GET_COMPANY_QUOTATION_COUNT: (id: string) =>
      `api/quotation/company/${id}/count/`,
    GET_COMPANY_QUOTATION: (id: string | undefined) =>
      `api/quotation/company/${id}/`,
    UPDATE: (id: string) => `api/quotation/${id}/`,
    UPLOAD_CERTIFICATE: () => `api/quotation/uploadCertificate`,
    SUBMIT_FOR_MANAGERS_APPROVAL: (id: string) =>
      `api/quotation/submitForManagersApproval/${id}/`,
  },
  ONE_TIME_LINKS: {
    GENERATE: "one-time-link/generate/",
    VALIDATE: "one-time-link/validate/",
    DELETE: (token: string) => `one-time-link/delete/${token}`,
  },
};
