import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import { Quotation, IQuotationsListQueryData } from "../../types/quotation";

/**
 * @description Dispatch this thunk to create quotation
 * @param {Quotation} bodyData - Object containing information for creating new quotation
 */
export const createQuotationThunk = createAsyncThunk(
  "quotations/createQuotationThunk",
  async (bodyData: Quotation, { rejectWithValue }) => {
    try {
      const { data } = await API.post(API_ROUTES.QUOTATION.CREATE, {
        ...bodyData,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get list of quotations
 * @param {IQuotationsListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllCompanyQuotationsThunk = createAsyncThunk(
  "quotations/getAllQuotationsThunk",
  async (
    queryData: IQuotationsListQueryData | undefined,
    { rejectWithValue },
  ) => {
    const { limit = 30, page = 1, companyId = "" } = queryData ?? {};

    try {
      const { data } = await API.get(
        API_ROUTES.QUOTATION.GET_LIST(companyId, limit, page),
      );
      return data?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
/**
 * @description Dispatch this thunk to get quotation by id
 * @param {string} id - String id to find current quotation
 */
export const getQuotationByIdThunk = createAsyncThunk(
  "quotations/getQuotationByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.QUOTATION.GET_BY_ID(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get company quotation count by id
 * @param {string} companyId - String id to get the current company quotation count
 */
export const getCompanyQuotationCountThunk = createAsyncThunk(
  "quotations/getCompanyQuotationCountThunk",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.QUOTATION.GET_COMPANY_QUOTATION_COUNT(companyId),
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get company quotations by id
 * @param {string} companyId - String id to get the current company quotations
 */
export const getCompanyQuotationThunk = createAsyncThunk(
  "quotations/getCompanyQuotationThunk",
  async (query: { [key: string]: string | undefined }, { rejectWithValue }) => {
    try {
      const { data } = await API.get(
        API_ROUTES.QUOTATION.GET_COMPANY_QUOTATION(query?.companyId),
        { params: query },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get quotation by id
 * @param {Quotation} values -an Object containing information for updating quotation
 */
export const updateQuotationThunk = createAsyncThunk(
  "quotations/updateQuotationThunk",
  async (values: Quotation, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await API.patch(
        API_ROUTES.QUOTATION.UPDATE(values.id?.toString() || ""),
        {
          ...values,
        },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to delete quotation by id
 * @param {string} id - String id to find current quotation for deleting
 */
export const deleteQuotationThunk = createAsyncThunk(
  "quotations/deleteQuotationThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.QUOTATION.DELETE(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to upload a file to s3
 */
export const uploadCertificateThunk = createAsyncThunk(
  "quotations/uploadCertificateThunk",
  async (file: File, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("certificate", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const { data } = await API.put(
        API_ROUTES.QUOTATION.UPLOAD_CERTIFICATE(),
        formData,
        config,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to submit quotation for managers approval
 * @param {string} id -a string with the quotation id
 */
export const submitForManagersApproval = createAsyncThunk(
  "quotations/submitForManagersApproval",
  async (id: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await API.post(
        API_ROUTES.QUOTATION.SUBMIT_FOR_MANAGERS_APPROVAL(id?.toString() || ""),
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
