import { Direction } from "./Direction";
import { Company } from "./company";
import { QuotationRequest } from "./quotationRequest";
import { User } from "./users";

export interface IQuotationsListQueryData {
  limit?: number;
  page?: number;
  companyId?: string;
}

export enum STATUSES {
  PENDING = "PENDING",
  PARTIALLY_ACCEPTED = "PARTIALLY_ACCEPTED",
  PARTIALLY_DECLINED = "PARTIALLY_DECLINED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export enum DECLINE_REASON {
  COST = "cost",
  SERVICE_LEVEL = "serviceLevel",
  BOTH = "both",
  OTHER = "other",
}

export type Quotation = {
  id?: string;
  name: string;
  cost?: string;
  reason?: string;
  declineReason?: string;
  reasonDescription?: string;
  direction?: Direction;
  pointOfContact?: string;
  isDirectMaterial?: boolean;
  material?: string;
  status?: STATUSES;
  isAssociatedWithProject?: boolean;
  carrierReference?: string;
  owner?: string | User;
  projectId?: string;
  company?: string | Company;
  quotationRequest?: QuotationRequest;
  createdAt?: string;
  updatedAt?: string;
};
