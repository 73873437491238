import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import {
  ICreateOrEditPartnerData,
  IPartnersListQueryData,
} from "../../types/partner";

/**
 * @description Dispatch this thunk to create partner
 * @param {ICreateOrEditPartnerData} bodyData - Object containing information for creating new partner
 */
export const createPartnerThunk = createAsyncThunk(
  "partners/createPartnerThunk",
  async (bodyData: ICreateOrEditPartnerData, { rejectWithValue }) => {
    try {
      // await register(bodyData);
      const { data } = await API.post(API_ROUTES.PARTNERS.CREATE, {
        ...bodyData,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get list of partners
 * @param {IPartnersListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllPartnersThunk = createAsyncThunk(
  "partners/getAllPartnersThunk",
  async (
    queryData: IPartnersListQueryData | undefined,
    { rejectWithValue },
  ) => {
    const { limit = 30, page = 1 } = queryData ?? {};

    try {
      const { data } = await API.get(API_ROUTES.PARTNERS.GET_LIST(limit, page));
      return data?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
/**
 * @description Dispatch this thunk to get partner by id
 * @param {string} id - String id to find current partner
 */
export const getPartnerByIdThunk = createAsyncThunk(
  "partners/getPartnerByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.PARTNERS.GET_BY_ID(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get partner by id
 * @param {ICreateOrEditPartnerData} values -an Object containing information for updating partner
 */
export const updatePartnerThunk = createAsyncThunk(
  "partners/updatePartnerThunk",
  async (
    values: ICreateOrEditPartnerData,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await API.patch(
        API_ROUTES.PARTNERS.UPDATE(values.id?.toString() || ""),
        {
          ...values,
        },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to delete partner by id
 * @param {string} id - String id to find current partner for deleting
 */
export const deletePartnerThunk = createAsyncThunk(
  "partners/deletePartnerThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.PARTNERS.DELETE(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
