import { createSelector } from "reselect";
import { RootState } from "..";

const vendorsState = (state: RootState) => state.vendors;

/**
 * @description Get list of vendors available on current page (pagination is used)
 */
export const vendorsSelector = createSelector(
  vendorsState,
  ({ vendors }) => vendors,
);

/**
 * @description Get data of selected vendor
 */
export const selectedVendorSelector = createSelector(
  vendorsState,
  ({ selectedVendor }) => selectedVendor,
);

/**
 * @description Get statuses of auth slice
 */
export const vendorsStatusSelector = createSelector(
  vendorsState,
  ({ statuses }) => statuses,
);
