import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme/theme";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Unauthorized: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          pt: 50,
          height: "100vh",
          backgroundImage: "url('/images/403.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Grid item>
          <Typography
            sx={{
              color: "white",
              fontWeight: "800",
              "text-shadow": "#000000AA 4px 4px;",
            }}
            color="primary"
            component="h1"
            variant="h1"
          >
            YOU ARE NOT AUTHORIZED
          </Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Unauthorized;
