import * as React from "react";
import { useState } from "react";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { getValidationSchema, initialValues, Props } from "./config";
import { Box, Modal } from "@mui/material";
import { style, StyledFormicError, StyledText } from "./styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { NotificationSeverity } from "../../helpers/constants";
import { ICreateOrEditPartnerData, Partner } from "../../types/partner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showNotificationThunk } from "../../redux/alert/thunks";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import {
  createPartnerThunk,
  updatePartnerThunk,
} from "../../redux/partners/thunks";
import MuiPhoneNumber from "mui-phone-number";

const CreateOrEditPartnerModal = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleCreatePartner = async (reqValues: ICreateOrEditPartnerData) => {
    setLoading(true);
    const result = await dispatch(
      props.isEditing
        ? updatePartnerThunk(reqValues)
        : createPartnerThunk(reqValues),
    );
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );
    props.handleCreateOrEditPartnerChange(result.payload.data as Partner);
    props.handleCreatePartnerModalDismiss();
  };

  const onPhoneNumberChanged = (value: any) => {
    formik.values.phone = value.toString();
  };

  const formik = useFormik({
    initialValues: props.partnerToEdit || initialValues,
    validationSchema: getValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqValues = {
        id: props.partnerToEdit?.id,
        name: values.name,
        email: values.email,
        phone: values.phone,
        pointOfContact: values.pointOfContact,
      };
      handleCreatePartner(reqValues as ICreateOrEditPartnerData).then();
    },
  });

  return (
    <Modal
      open={props.createOrEditPartnerModal}
      onClose={props.handleCreatePartnerModalDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <StyledText id="modal-modal-title">
          {props.isEditing ? "Update Partner" : "Create Partner"}
        </StyledText>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                type="text"
                id="name"
                value={formik?.values?.name}
              />
              <ErrorMessage name="name" component={StyledFormicError} />
              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                disabled={props.isEditing}
                value={formik?.values?.email}
              />
              <ErrorMessage name="email" component={StyledFormicError} />
              <MuiPhoneNumber
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                autoFocus
                defaultCountry="ca"
                onChange={onPhoneNumberChanged}
                value={formik?.values?.phone}
              />
              <ErrorMessage name="phone" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                id="pointOfContact"
                label="Point Of Contact"
                name="pointOfContact"
                autoComplete="pointOfContact"
                autoFocus
                value={formik?.values?.pointOfContact}
              />
              <ErrorMessage
                name="pointOfContact"
                component={StyledFormicError}
              />

              <Grid
                sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={props.handleCreatePartnerModalDismiss}
                >
                  Cancel
                </Button>

                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={30}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Grid>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Modal>
  );
};

export default CreateOrEditPartnerModal;
