import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PlusOne from "@mui/icons-material/PlusOne";
import { TABLE_ACTIONS } from "../../../../helpers/constants";
import CreateOrEditUserModal from "../../../../modals/CreateOrEditUserModal/CreateOrEditUserModal";
import { ROLES, User } from "../../../../types/users";
import ConfirmActionModal from "../../../../modals/ConfirmActionModal/ConfirmActionModal";
import CustomTable from "../../../../components/Table/CustomTable";
import { Company } from "../../../../types/company";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { userSelector } from "../../../../redux/auth/selectors";

export type ActionButton = {
  title: string;
  icon: any;
  handleClick: () => void;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
};

export type Props = {
  title: string;
  users: User[] | null;
  extraButtons: ActionButton[] | undefined;
  handleCreateOrUpdateUser: (user?: User) => void;
  handleDelete: (userIdToDelete: string) => Promise<void>;
  allowedRoles: ROLES[];
  company?: Company;
};

export default function UserTable({
  title,
  users,
  extraButtons,
  handleCreateOrUpdateUser,
  handleDelete,
  allowedRoles,
  company,
}: Props) {
  const [createOrEditUserModal, setCreateOrEditUserModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userToEdit, setUserToEdit] = useState(undefined as User | undefined);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const user = useAppSelector(userSelector);

  const handleActionClick = (action: TABLE_ACTIONS, id: string) => {
    switch (action) {
      case TABLE_ACTIONS.EDIT:
        const userToEdit = users?.find((user) => user.id === id);
        if (userToEdit) {
          handleCreateOrUpdateUserModalOpen(true, userToEdit);
        }
        break;
      case TABLE_ACTIONS.DELETE:
        setUserIdToDelete(id);
        setDeleteUserModal(true);
        break;
      default:
        break;
    }
  };

  const handleCreateOrUpdateUserModalOpen = (
    isEditing?: boolean,
    userToEdit?: User,
  ) => {
    setIsEditing(!!isEditing);
    setUserToEdit(userToEdit);
    setCreateOrEditUserModal(true);
  };

  const handleCreateUserModalDismiss = () => {
    setCreateOrEditUserModal(false);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography color={"primary"} variant="h4">
          {title}
        </Typography>

        <Stack direction="row">
          {user?.role === ROLES.ADMIN && (
            <Button
              sx={{ ml: 0.5, mr: 0.5 }}
              variant="contained"
              color="primary"
              onClick={() => handleCreateOrUpdateUserModalOpen()}
              startIcon={<PlusOne />}
            >
              New User
            </Button>
          )}

          {extraButtons?.map((button) => (
            <Button
              key={button.title}
              sx={{ ml: 0.5, mr: 0.5 }}
              variant="contained"
              color={button.color}
              onClick={() => button?.handleClick()}
              startIcon={button.icon}
            >
              {button.title}
            </Button>
          ))}
        </Stack>
      </Stack>

      <CustomTable
        entities={users}
        handleActionClick={handleActionClick}
        fieldToFilter={"firstname"}
        columns={[
          {
            id: "name",
            label: "Name",
            value: (row: any) => `${row.firstname} ${row.lastname}`,
          },
          { id: "email", label: "Email", value: "email" },
          { id: "role", label: "Role", value: "email" },
          { id: "actions", value: "" },
        ]}
        isSelectable={false}
        extraActionItems={[]}
      />

      {createOrEditUserModal && (
        <CreateOrEditUserModal
          allowedRoles={allowedRoles}
          handleCreateOrEditUserChange={handleCreateOrUpdateUser}
          createUserModal={createOrEditUserModal}
          handleCreateUserModalDismiss={handleCreateUserModalDismiss}
          userToEdit={userToEdit}
          isEditing={isEditing}
          company={company}
        />
      )}
      {deleteUserModal && (
        <ConfirmActionModal
          title={"Heads up!"}
          message={"Are you sure you want to delete this user?"}
          show={deleteUserModal}
          onConfirm={() => handleDelete(userIdToDelete)}
          onDismiss={() => {
            setDeleteUserModal(false);
          }}
        />
      )}
    </>
  );
}
