import { Grid } from "@mui/material";
import * as React from "react";

import Container from "../../components/Container";
import TotalCost from "./charts/TotalCost";
import TopCostPerPartNumber from "./charts/TopCostPerPartNumber";
import TopCostPerPointOfContact from "./charts/TopCostPerPointOfContact";
import { Direction } from "../../types/Direction";

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
        sx={{ mb: 2 }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <TotalCost />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ mb: 2 }}
      >
        <Grid item xs={4} sm={8} md={6}>
          <TopCostPerPartNumber isDirect={true} />
        </Grid>
        <Grid item xs={4} sm={8} md={6}>
          <TopCostPerPartNumber isDirect={false} />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={6}>
          <TopCostPerPointOfContact direction={Direction.INBOUND} />
        </Grid>
        <Grid item xs={4} sm={8} md={6}>
          <TopCostPerPointOfContact direction={Direction.OUTBOUND} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
