import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import { IUsersListQueryData, User } from "../../types/users";
import { ICreateOrUpdateUserCredentials } from "../../types/auth";
import { registerUser } from "../auth/thunks";
import { Company } from "../../types/company";

/**
 * @description Dispatch this thunk to create user
 * @param {User} bodyData - Object containing information for creating new user
 */
export const createUserThunk = createAsyncThunk(
  "users/createUserThunk",
  async (bodyData: ICreateOrUpdateUserCredentials, { rejectWithValue }) => {
    try {
      await registerUser(
        bodyData?.email?.toString() || "",
        `${bodyData?.firstname} ${bodyData?.lastname}`,
      );
      const { data } = await API.post(API_ROUTES.USERS.CREATE, { ...bodyData });
      return data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get list of users
 * @param {IUsersListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllUsersThunk = createAsyncThunk(
  "users/getAllUsersThunk",
  async (queryData: IUsersListQueryData | undefined, { rejectWithValue }) => {
    const { limit = 30, page = 1 } = queryData ?? {};

    try {
      const { data } = await API.get(API_ROUTES.USERS.GET_LIST(limit, page));
      return data?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
/**
 * @description Dispatch this thunk to get user by id
 * @param {string} id - String id to find current user
 */
export const getUserByIdThunk = createAsyncThunk(
  "users/getUserByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.USERS.GET_BY_ID(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get user by id
 * @param {User} values -an Object containing information for updating user
 */
export const updateUserThunk = createAsyncThunk(
  "users/updateUserThunk",
  async (
    values: ICreateOrUpdateUserCredentials,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await API.patch(
        API_ROUTES.USERS.UPDATE(values.id?.toString() || ""),
        {
          ...values,
        },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to update users key
 * @param {User} values -an Object containing information for updating user
 */
export const updateUsersKeyThunk = createAsyncThunk(
  "users/updateUsersKeyThunk",
  async (
    values: { users: User[]; company: Company },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await API.patch(API_ROUTES.USERS.UPDATE_KEY, values);
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to delete user by id
 * @param {string} id - String id to find current user for deleting
 */
export const deleteUserThunk = createAsyncThunk(
  "users/deleteUserThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.USERS.DELETE(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
