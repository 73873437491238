import { createSlice } from "@reduxjs/toolkit";
import { IStoreStatuses, STATUS_ENUM } from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import {
  createVendorThunk,
  getAllVendorsThunk,
  getVendorByIdThunk,
  deleteVendorThunk,
  updateVendorThunk,
} from "./thunks";
import { Vendor } from "../../types/vendor";

interface IState {
  vendors: Vendor[] | null;
  selectedVendor: Vendor | null;
  statuses: IStoreStatuses;
}

const initialState: IState = {
  vendors: null,
  selectedVendor: null,
  statuses: StatusGenerator.generateStatuses([
    createVendorThunk.typePrefix,
    getAllVendorsThunk.typePrefix,
    getVendorByIdThunk.typePrefix,
    deleteVendorThunk.typePrefix,
    updateVendorThunk.typePrefix,
  ]),
};

const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    setSelectedVendor: (state, action) => {
      state.selectedVendor = action.payload.data ?? null;
    },
    setVendors: (state, action) => {
      state.vendors = action.payload.data ?? null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createVendorThunk.pending, (state) => {
        state.statuses[createVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(createVendorThunk.rejected, (state, { error }) => {
        state.statuses[createVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(createVendorThunk.fulfilled, (state, { payload }) => {
        state.statuses[createVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedVendor = payload;
      })
      .addCase(getAllVendorsThunk.pending, (state) => {
        state.statuses[getAllVendorsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllVendorsThunk.rejected, (state, { error }) => {
        state.statuses[getAllVendorsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllVendorsThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllVendorsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.vendors = payload;
      })
      .addCase(getVendorByIdThunk.pending, (state) => {
        state.statuses[getVendorByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getVendorByIdThunk.rejected, (state, { error }) => {
        state.statuses[getVendorByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getVendorByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getVendorByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedVendor = payload.data;
      })
      .addCase(updateVendorThunk.pending, (state) => {
        state.statuses[updateVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updateVendorThunk.rejected, (state, { error }) => {
        state.statuses[updateVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updateVendorThunk.fulfilled, (state, { payload }) => {
        state.statuses[updateVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedVendor = payload;
      })
      .addCase(deleteVendorThunk.pending, (state) => {
        state.statuses[deleteVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteVendorThunk.rejected, (state, { error }) => {
        state.statuses[deleteVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteVendorThunk.fulfilled, (state) => {
        state.statuses[deleteVendorThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default vendorsSlice;
