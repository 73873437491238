import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { userSelector } from "../redux/auth/selectors";

type Props = {
  element: ReactElement;
  roles: string[];
};

const ProtectedRoute = ({ element, roles }: Props) => {
  const user = useAppSelector(userSelector);
  return roles.includes(user?.role as string) ? (
    element
  ) : (
    <Navigate to="/403" />
  );
};

export default ProtectedRoute;
