import * as React from "react";
import { useState } from "react";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { getValidationSchema, initialValues, Props } from "./config";
import { Box, IconButton, Modal, ThemeProvider } from "@mui/material";
import { style, StyledFormicError, StyledText } from "./styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../theme/theme";
import { changePasswordThunk } from "../../redux/auth/thunks";
import { showNotificationThunk } from "../../redux/alert/thunks";
import { Messages, NotificationSeverity } from "../../helpers/constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { IChangePassword } from "../../types/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

const ResetPasswordModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [isOldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setNewPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showOldPassword = () => {
    setOldPasswordVisible(!isOldPasswordVisible);
  };
  const showNewPassword = () => {
    setNewPasswordVisible(!isNewPasswordVisible);
  };

  const handlePasswordChange = (reqValues: IChangePassword) => {
    setLoading(true);
    try {
      dispatch(changePasswordThunk(reqValues));
      dispatch(
        showNotificationThunk({
          message: Messages.successPassword,
          severity: NotificationSeverity.success,
        }),
      );
      if (props.handlePasswordChanged) {
        props.handlePasswordChanged();
      }
      props.handlePasswordModalClose();
    } catch (e: any) {
      dispatch(
        showNotificationThunk({
          message: e.message,
          severity: NotificationSeverity.error,
        }),
      );
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema,
    onSubmit: (values) => {
      const reqValues = {
        password: values.password,
        newPassword: values.newPassword,
      };
      handlePasswordChange(reqValues);
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.passwordModal}
        onClose={props.handlePasswordModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledText id="modal-modal-title">Change Password</StyledText>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched["password"] && formik.errors["password"]
                  }
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Old Password"
                  autoComplete="Old Password"
                  type={isOldPasswordVisible ? "text" : "password"}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showOldPassword}
                          edge="end"
                        >
                          {isOldPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage name="password" component={StyledFormicError} />

                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched["newPassword"] &&
                    formik.errors["newPassword"]
                  }
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="New Password"
                  name="newPassword"
                  label="New Password"
                  type={isNewPasswordVisible ? "text" : "password"}
                  id="newPassword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={showNewPassword}
                          edge="end"
                        >
                          {isNewPasswordVisible ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="newPassword"
                  component={StyledFormicError}
                />

                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="Repeat Password"
                  name="repeatPassword"
                  label="Repeat password"
                  type="password"
                  id="repeatPassword"
                />
                <ErrorMessage
                  name="repeatPassword"
                  component={StyledFormicError}
                />

                <Grid
                  sx={{
                    mt: 5,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.handlePasswordModalClose}
                  >
                    Cancel
                  </Button>

                  <Box sx={{ position: "relative" }}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={30}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Box>
            </form>
          </FormikProvider>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ResetPasswordModal;
