import { createSelector } from "reselect";
import { RootState } from "..";

const companiesState = (state: RootState) => state.companies;

/**
 * @description Get list of companies available on current page (pagination is used)
 */
export const companiesSelector = createSelector(
  companiesState,
  ({ companies }) => companies,
);

/**
 * @description Get data of selected company
 */
export const selectedCompanySelector = createSelector(
  companiesState,
  ({ selectedCompany }) => selectedCompany,
);

/**
 * @description Get statuses of auth slice
 */
export const companiesStatusSelector = createSelector(
  companiesState,
  ({ statuses }) => statuses,
);
