import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { AdminRoutes } from "./config/routes";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import { useAppSelector } from "./hooks/useAppSelector";
import { userSelector } from "./redux/auth/selectors";
import { LocalStorage } from "./utils/LocalStorage";
import Users from "./pages/Users/list";
import CompaniesList from "./pages/Companies/list";
import EditCompany from "./pages/Companies/edit";
import NoMatch from "./pages/NoMatch";
import SetPassword from "./pages/SetPassword";
import Notification from "./utils/Notification";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import VendorsList from "./pages/Vendors/list/VendorsList";
import AddQuotation from "./pages/Quotations/add";
import QuotationsList from "./pages/Quotations/list";
import EditQuotation from "./pages/Quotations/edit";
import Dashboard from "./pages/Dashboard";
import Unauthorized from "./pages/Unauthorized";
import ProtectedRoute from "./utils/ProtectedRoute";
import { ROLES } from "./types/users";
import PartnersList from "./pages/Partners/list";

const App: React.FC = () => {
  const user = useAppSelector(userSelector);
  const token = LocalStorage.getToken();
  useEffect(() => {}, [token, user]);

  return (
    <Router>
      <Routes>
        {!user?.id || !token ? (
          <>
            <Route path="*" element={<Login />} />
            <Route
              path={AdminRoutes.ResetPassword}
              element={<ResetPassword />}
            />
            <Route
              path={AdminRoutes.ResetPasswordRequest}
              element={<ResetPasswordRequest />}
            />
            <Route path={AdminRoutes.SetPassword} element={<SetPassword />} />
          </>
        ) : (
          <>
            <Route
              path={AdminRoutes.ResetPassword}
              element={<ResetPassword />}
            />
            <Route
              path={AdminRoutes.User}
              element={
                <ProtectedRoute element={<Users />} roles={[ROLES.ADMIN]} />
              }
            />
            <Route
              path={AdminRoutes.Vendors}
              element={
                <ProtectedRoute
                  element={<VendorsList />}
                  roles={[ROLES.ADMIN]}
                />
              }
            />
            <Route
              path={AdminRoutes.Partners}
              element={
                <ProtectedRoute
                  element={<PartnersList />}
                  roles={[ROLES.ADMIN]}
                />
              }
            />
            <Route
              path={AdminRoutes.Companies}
              element={
                <ProtectedRoute
                  element={<CompaniesList />}
                  roles={[ROLES.ADMIN]}
                />
              }
            />
            <Route
              path={AdminRoutes.CompanyEdit}
              element={
                <ProtectedRoute
                  element={<EditCompany />}
                  roles={[ROLES.MANAGER, ROLES.ADMIN]}
                />
              }
            />

            <Route
              path={AdminRoutes.QuotationAdd}
              element={
                <ProtectedRoute
                  element={<AddQuotation />}
                  roles={[ROLES.MANAGER, ROLES.COORDINATOR]}
                />
              }
            />
            <Route
              path={AdminRoutes.QuotationEdit}
              element={
                <ProtectedRoute
                  element={<EditQuotation />}
                  roles={[ROLES.MANAGER, ROLES.COORDINATOR]}
                />
              }
            />

            <Route
              path={AdminRoutes.QuotationList}
              element={
                <ProtectedRoute
                  element={<QuotationsList />}
                  roles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.COORDINATOR]}
                />
              }
            />

            <Route
              path={AdminRoutes.Dashboard}
              element={
                <ProtectedRoute
                  element={<Dashboard />}
                  roles={[ROLES.MANAGER, ROLES.COORDINATOR]}
                />
              }
            />

            <Route
              path={AdminRoutes.Login}
              element={
                <Navigate
                  replace
                  to={
                    user.role === ROLES.ADMIN
                      ? AdminRoutes.Companies
                      : `/dashboard/${user?.company?.id}`
                  }
                />
              }
            />

            <Route
              path={AdminRoutes.SignUp}
              element={<Navigate replace to={AdminRoutes.Login} />}
            />
            <Route
              path={AdminRoutes.SetPassword}
              element={<Navigate replace to={AdminRoutes.Login} />}
            />
            <Route path="*" element={<NoMatch />} />
            <Route path="/403" element={<Unauthorized />} />
          </>
        )}
      </Routes>
      <Notification onClose={() => {}} />
    </Router>
  );
};

export default App;
