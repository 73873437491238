import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TABLE_ACTIONS } from "../../../helpers/constants";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { getAllPartnersThunk } from "../../../redux/partners/thunks";
import ConfirmActionModal from "../../../modals/ConfirmActionModal/ConfirmActionModal";
import CreateOrEditPartnerModal from "../../../modals/CreateOrEditPartnerModal/CreateOrEditPartnerModal";
import CustomTable from "../../../components/Table/CustomTable";
import { Partner } from "../../../types/partner";
import { ActionButton } from "../../Users/list/components/UserTable";
import Button from "@mui/material/Button";
import PlusOne from "@mui/icons-material/PlusOne";

export type Props = {
  title: string;
  partners: Partner[] | null;
  extraButtons: ActionButton[] | undefined;
  handleCreateOrUpdatePartner: (partner?: Partner) => void;
  handleDelete: (userIdToDelete: string) => Promise<void>;
};

export default function PartnersTable({
  title,
  partners,
  extraButtons,
  handleCreateOrUpdatePartner,
  handleDelete,
}: Props) {
  const dispatch = useAppDispatch();
  const [createOrEditPartnerModal, setCreateOrEditPartnerModal] =
    useState(false);
  const [deletePartnerModal, setDeletePartnerModal] = useState(false);
  const [partnerIdToDelete, setPartnerIdToDelete] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [partnerToEdit, setPartnerToEdit] = useState(
    undefined as Partner | undefined,
  );
  useEffect(() => {
    dispatch(getAllPartnersThunk());
  }, [dispatch]);

  const handleActionClick = (action: TABLE_ACTIONS, id: string) => {
    switch (action) {
      case TABLE_ACTIONS.EDIT:
        const partnerToEdit = partners?.find((partner) => partner.id === id);
        if (partnerToEdit) {
          handleCreateOrUpdateUserModalOpen(true, partnerToEdit);
        }
        break;
      case TABLE_ACTIONS.DELETE:
        setPartnerIdToDelete(id);
        setDeletePartnerModal(true);
        break;
      default:
        break;
    }
  };

  const handleCreateOrUpdateUserModalOpen = (
    isEditing?: boolean,
    partnerToEdit?: Partner,
  ) => {
    setIsEditing(!!isEditing);
    setPartnerToEdit(partnerToEdit);
    setCreateOrEditPartnerModal(true);
  };

  const handleCreatePartner = () => {
    setCreateOrEditPartnerModal(false);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography color={"primary"} variant="h4">
          {title}
        </Typography>
        <Stack direction="row">
          <Button
            sx={{ ml: 0.5, mr: 0.5 }}
            variant="contained"
            color="primary"
            onClick={() => handleCreateOrUpdateUserModalOpen()}
            startIcon={<PlusOne />}
          >
            New Partner
          </Button>

          {extraButtons?.map((button) => (
            <Button
              key={button.title}
              sx={{ ml: 0.5, mr: 0.5 }}
              variant="contained"
              color={button.color}
              onClick={() => button?.handleClick()}
              startIcon={button.icon}
            >
              {button.title}
            </Button>
          ))}
        </Stack>
      </Stack>

      <CustomTable
        entities={partners}
        handleActionClick={handleActionClick}
        fieldToFilter={"name"}
        columns={[
          {
            id: "name",
            label: "Name",
            value: "name",
          },
          {
            id: "email",
            label: "Email",
            value: "email",
          },
          { id: "actions", value: "" },
        ]}
        isSelectable={false}
        allowedMenuItems={[TABLE_ACTIONS.EDIT, TABLE_ACTIONS.DELETE]}
        extraActionItems={[]}
      />

      {createOrEditPartnerModal && (
        <CreateOrEditPartnerModal
          createOrEditPartnerModal={createOrEditPartnerModal}
          handleCreatePartnerModalDismiss={handleCreatePartner}
          handleCreateOrEditPartnerChange={handleCreateOrUpdatePartner}
          isEditing={isEditing}
          partnerToEdit={partnerToEdit}
        />
      )}
      {deletePartnerModal && (
        <ConfirmActionModal
          title={"Heads up!"}
          message={"Are you sure you want to delete this partner?"}
          show={deletePartnerModal}
          onConfirm={() => handleDelete(partnerIdToDelete)}
          onDismiss={() => {
            setDeletePartnerModal(false);
          }}
        />
      )}
    </>
  );
}
