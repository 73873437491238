import * as Yup from "yup";
import { ROLES } from "../../types/users";

export const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  role: ROLES.ADMIN,
  location: {
    city: "",
    state: "",
    country: "",
  },
};

export const getValidationSchema = () =>
  Yup.object().shape({
    firstname: Yup.string().required("This field is required"),
    lastname: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    role: Yup.string().required("This field is required"),
  });
