import { RootState } from "..";
import { createSelector } from "reselect";

const alertState = (state: RootState) => state.alert;
/**
 * @description Get data of logged user
 */
export const alertSelector = createSelector(
  alertState,
  ({ showAlert, message, severity }) => ({ showAlert, message, severity }),
);
