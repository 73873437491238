import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";
import { ICompaniesListQueryData } from "../../types/company";
import { Company } from "../../types/company";

/**
 * @description Dispatch this thunk to create company
 * @param {Company} bodyData - Object containing information for creating new company
 */
export const createCompanyThunk = createAsyncThunk(
  "companies/createCompanyThunk",
  async (bodyData: Company, { rejectWithValue }) => {
    try {
      // await register(bodyData);
      const { data } = await API.post(API_ROUTES.COMPANIES.CREATE, {
        ...bodyData,
      });
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get list of companies
 * @param {ICompaniesListQueryData} queryData - Object containing limit and page (used for pagination)
 */
export const getAllCompaniesThunk = createAsyncThunk(
  "companies/getAllCompaniesThunk",
  async (
    queryData: ICompaniesListQueryData | undefined,
    { rejectWithValue },
  ) => {
    const { limit = 30, page = 1 } = queryData ?? {};

    try {
      const { data } = await API.get(
        API_ROUTES.COMPANIES.GET_LIST(limit, page),
      );
      return data?.data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
/**
 * @description Dispatch this thunk to get company by id
 * @param {string} id - String id to find current company
 */
export const getCompanyByIdThunk = createAsyncThunk(
  "companies/getCompanyByIdThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.get(API_ROUTES.COMPANIES.GET_BY_ID(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to get company by id
 * @param {Company} values -an Object containing information for updating company
 */
export const updateCompanyThunk = createAsyncThunk(
  "companies/updateCompanyThunk",
  async (values: Company, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await API.patch(
        API_ROUTES.COMPANIES.UPDATE(values.id?.toString() || ""),
        {
          ...values,
        },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);

/**
 * @description Dispatch this thunk to delete company by id
 * @param {string} id - String id to find current company for deleting
 */
export const deleteCompanyThunk = createAsyncThunk(
  "companies/deleteCompanyThunk",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await API.delete(API_ROUTES.COMPANIES.DELETE(id));
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error?.response?.data || { message: "Something went wrong" },
      );
    }
  },
);
