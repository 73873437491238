export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export enum Messages {
  errorPassword = "Sorry, failed to change password",
  errorNeedVerification = "Sorry, you need to verify your account first, check your email.",
  successPassword = "Password changed successfully. Login to start using Violet Space.",
  successSetPassword = "Password created successfully. Login to start using Violet Space.",
  successUpdateUser = "User updated successfully",
  successCreateUser = "User created successfully",
  successResetPasswordRequest = "An email was sent to you with the instructions to finish resetting your email.",
  errorCreateUser = "Something went wrong while creating the user, please try again later",
  errorUpdateUser = "Something went wrong while updating the user, please try again later",
  successEditPost = "Post changed successfully",
  successCreatePost = "Post created successfully",
  successUpdateCompany = "Company updated successfully",
  successCreateQuotation = "Quotation Request created successfully",
  successUpdateQuotation = "Quotation Request updated successfully",
  successSubmittingQuotationForManagersApproval = "Quotation Request submitted successfully",
  errorCreateQuotation = "Something went wrong while requesting the quotation, please check your data and try again later",
}

export enum LoginResponseType {
  ACTION_REQUIRED = "ACTION_REQUIRED",
  SUCCESS = "SUCCESS",
}

export enum LoginSteps {
  CONTINUE_SIGN_IN_WITH_MFA_SELECTION = "CONTINUE_SIGN_IN_WITH_MFA_SELECTION",
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP = "CONTINUE_SIGN_IN_WITH_TOTP_SETUP",
  CONFIRM_SIGN_IN_WITH_SMS_CODE = "CONFIRM_SIGN_IN_WITH_SMS_CODE",
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = "CONFIRM_SIGN_IN_WITH_TOTP_CODE",
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE",
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED",
  CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP",
  RESET_PASSWORD = "RESET_PASSWORD",
  DONE = "DONE",
}

export enum NotificationSeverity {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum TABLE_ACTIONS {
  EDIT = "edit",
  DELETE = "delete",
  VIEW = "view",
  QUOTATIONS = "quotations",
}

export enum ENTITIES {
  USERS = "users",
  VENDORS = "vendors",
}
