import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import FilterList from "@mui/icons-material/FilterList";

type UserTableToolbarProps = {
  numSelected: number;
  filter: string;
  onFilter: any;
};
export default function CustomTableToolbar({
  numSelected,
  filter,
  onFilter,
}: UserTableToolbarProps) {
  return (
    <Toolbar
      sx={{
        height: 96,
        display: "flex",
        justifyContent: "space-between",
        p: (theme) => theme.spacing(0, 1, 0, 3),
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <OutlinedInput
          value={filter}
          onChange={onFilter}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <Search sx={{ color: "text.disabled", width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      <Tooltip title="Filter list">
        <IconButton>
          <FilterList />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}
