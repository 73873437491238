import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Modal, ThemeProvider } from "@mui/material";
import { style, StyledTitle } from "./styles";
import Button from "@mui/material/Button";
import theme from "../../theme/theme";
import CustomTable from "../../components/Table/CustomTable";
import Stack from "@mui/material/Stack";

export type Props = {
  show: boolean;
  title: string;
  onDismiss: () => void;
  onSelectDone: (selected: string[]) => void;
  getEntities: () => any[];
  entitiesIdsAlreadySelected: string[];
  columns: { id: string; label?: string; value: string | Function }[];
  fieldToFilter: string;
};

const SelectEntitiesModal = ({
  show,
  title,
  onDismiss,
  onSelectDone,
  getEntities,
  entitiesIdsAlreadySelected,
  columns,
  fieldToFilter,
}: Props) => {
  const [selected, setSelected] = useState([] as string[]);

  useEffect(() => {
    setSelected(entitiesIdsAlreadySelected);
  }, [entitiesIdsAlreadySelected]);

  const confirm = () => {
    onSelectDone(selected);
    onDismiss();
  };

  const handleEntitiesSelectionChanged = (selectedIds: string[]) => {
    setSelected(selectedIds);
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={show}
        onClose={onDismiss}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledTitle id="modal-modal-title">{title}</StyledTitle>
          <CustomTable
            entities={getEntities()}
            columns={columns}
            handleActionClick={() => {}}
            fieldToFilter={fieldToFilter}
            isSelectable={true}
            onSelectionChanged={handleEntitiesSelectionChanged}
            entitiesIdsAlreadySelected={entitiesIdsAlreadySelected}
            extraActionItems={[]}
          />

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            mt={2}
          >
            <Button variant="contained" color="error" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={confirm}>
              Done
            </Button>
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default SelectEntitiesModal;
