import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "80%",
  height: "70%",
  maxHeight: "70%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "12px",
  boxShadow: "0px 4px 20px rgba(60, 133, 191, 0.18)",
  p: 4,
  overflow: "auto",
};

export const StyledTextView = styled(TextField)(() => ({
  "& .MuiInput-underline:before": {
    border: "none",
  },
  "&:hover .MuiInput-underline:before": {
    border: "none",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
}));
