import * as Yup from "yup";

export const initialValues = {
  email: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string().required("This field is required"),
  });
