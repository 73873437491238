import CustomTableToolbar from "./CustomTableToolbar";
import TableContainer from "@mui/material/TableContainer";
import CustomTableHead from "./CustomTableHead";
import TableBody from "@mui/material/TableBody";
import CustomTableRow from "./CustomTableRow";
import { SortOrder, TABLE_ACTIONS } from "../../helpers/constants";
import TableEmptyRows from "./TableEmptyRows";
import { applyFilter, emptyRows, getComparator } from "./utils";
import TableNoData from "./TableNoData";
import TablePagination from "@mui/material/TablePagination";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { ActionItem } from "./types";
import { Quotation, STATUSES } from "../../types/quotation";
import { ROLES } from "../../types/users";
import { useAppSelector } from "../../hooks/useAppSelector";
import { userSelector } from "../../redux/auth/selectors";

type Props = {
  entities: any[] | null;
  columns: { id: string; label?: string; value: string | Function }[];
  handleActionClick: (action: TABLE_ACTIONS, id: string) => void;
  fieldToFilter: string;
  isSelectable: boolean;
  onSelectionChanged?: (selected: string[]) => void;
  entitiesIdsAlreadySelected?: string[];
  allowedMenuItems?: string[];
  extraActionItems: ActionItem[];
};

export default function CustomTable({
  entities,
  columns,
  handleActionClick,
  fieldToFilter,
  isSelectable,
  onSelectionChanged,
  entitiesIdsAlreadySelected,
  allowedMenuItems = [
    TABLE_ACTIONS.EDIT,
    TABLE_ACTIONS.DELETE,
    TABLE_ACTIONS.VIEW,
  ],
  extraActionItems,
}: Props) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(SortOrder.asc);
  const [selected, setSelected] = useState([] as string[]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const user = useAppSelector(userSelector);

  useEffect(() => {
    setSelected(entitiesIdsAlreadySelected || []);
  }, [entitiesIdsAlreadySelected]);

  const handleSort = (_: any, id: string) => {
    const isAsc = orderBy === id && order === SortOrder.asc;
    if (id !== "") {
      setOrder(isAsc ? SortOrder.desc : SortOrder.asc);
      setOrderBy(id);
    }
  };

  const handleClick = (event: any, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    if (onSelectionChanged) {
      onSelectionChanged(newSelected);
    }
  };

  const getRowData = (row: any) => {
    const rowData = [];
    for (const r of columns) {
      if (r.id === "actions") {
        rowData.push("actions");
      } else if (typeof r.value === "function") {
        rowData.push(r.value(row));
      } else {
        rowData.push(row[r.id]);
      }
    }
    return rowData;
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: any) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const getMenuItems = (row: Quotation) => {
    if (
      user?.role === ROLES.MANAGER &&
      [STATUSES.DECLINED, STATUSES.ACCEPTED].includes(row.status as STATUSES)
    ) {
      return [TABLE_ACTIONS.EDIT, TABLE_ACTIONS.VIEW];
    } else if (
      user?.role === ROLES.COORDINATOR &&
      [STATUSES.DECLINED, STATUSES.ACCEPTED].includes(row.status as STATUSES)
    ) {
      return [TABLE_ACTIONS.VIEW];
    }
    return allowedMenuItems;
  };

  const dataFiltered = applyFilter({
    inputData: entities || [],
    comparator: getComparator(order, orderBy),
    filterName,
    fieldToFilter,
  });

  const notFound = !dataFiltered.length && !!filterName;

  return (
    <Card>
      <CustomTableToolbar
        numSelected={selected.length}
        filter={filterName}
        onFilter={handleFilterByName}
      />

      <TableContainer sx={{ overflow: "unset" }}>
        <Table sx={{ minWidth: 800 }}>
          <CustomTableHead
            order={order}
            orderBy={orderBy}
            rowCount={entities?.length || 0}
            numSelected={selected.length}
            onRequestSort={handleSort}
            onSelectAllClick={() => {
              if (selected.length === entities?.length) {
                setSelected([]);
              } else {
                setSelected(entities?.map((row) => row.id) || []);
              }
            }}
            headLabel={columns}
            isSelectable={isSelectable}
          />
          <TableBody>
            {dataFiltered
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => (
                <CustomTableRow
                  key={row.id}
                  data={getRowData(row)}
                  selected={selected.indexOf(row?.id) !== -1}
                  handleSelected={(event: any) => handleClick(event, row.id)}
                  handleActionClicked={(action: TABLE_ACTIONS) =>
                    handleActionClick(action, row.id)
                  }
                  isSelectable={isSelectable}
                  allowedMenuItems={getMenuItems(row)}
                  extraActionItems={extraActionItems}
                />
              ))}

            <TableEmptyRows
              height={77}
              emptyRows={emptyRows(page, rowsPerPage, entities?.length || 0)}
            />

            {notFound && <TableNoData query={filterName} />}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        page={page}
        component="div"
        count={entities?.length || 0}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
