import * as React from "react";
import { useState } from "react";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { getValidationSchema, initialValues } from "./config";
import { Box, MenuItem, Modal } from "@mui/material";
import { style, StyledFormicError, StyledText } from "./styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { NotificationSeverity } from "../../helpers/constants";
import { ICreateOrUpdateUserCredentials } from "../../types/auth";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showNotificationThunk } from "../../redux/alert/thunks";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { createUserThunk, updateUserThunk } from "../../redux/users/thunks";
import { ROLES, User } from "../../types/users";
import { useAppSelector } from "../../hooks/useAppSelector";
import { userSelector } from "../../redux/auth/selectors";
import { Company } from "../../types/company";

export type Props = {
  createUserModal: boolean;
  handleCreateUserModalDismiss: () => void;
  handleCreateOrEditUserChange: (user?: User) => string | void;
  allowedRoles: ROLES[];
  isEditing?: boolean;
  userToEdit?: User;
  company?: Company;
};

const CreateOrEditUserModal = ({
  createUserModal,
  handleCreateUserModalDismiss,
  handleCreateOrEditUserChange,
  allowedRoles,
  isEditing,
  userToEdit,
  company,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const handleCreateUser = async (
    reqValues: ICreateOrUpdateUserCredentials,
  ) => {
    setLoading(true);
    const result = await dispatch(
      isEditing ? updateUserThunk(reqValues) : createUserThunk(reqValues),
    );
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );
    handleCreateOrEditUserChange(result.payload.data as User);
    handleCreateUserModalDismiss();
  };

  const formik = useFormik({
    initialValues: userToEdit || initialValues,
    validationSchema: getValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqValues = {
        id: userToEdit?.id,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        role: values.role,
        location: values.location,
        secretKey: user?.secretKey,
        company: user?.company || company,
      };
      handleCreateUser(reqValues as ICreateOrUpdateUserCredentials).then();
    },
  });

  return (
    <Modal
      open={createUserModal}
      onClose={handleCreateUserModalDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <StyledText id="modal-modal-title">
          {isEditing ? "Update User" : "Create User"}
        </StyledText>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="firstname"
                label="First Name"
                type="text"
                id="firstname"
                value={formik?.values?.firstname}
              />
              <ErrorMessage name="firstname" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="lastname"
                label="Last Name"
                type="text"
                id="lastname"
                value={formik?.values?.lastname}
              />
              <ErrorMessage name="lastname" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                disabled={isEditing}
                value={formik?.values?.email}
              />
              <ErrorMessage name="email" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                id="role"
                name="role"
                select
                label="Role"
                autoComplete="role"
                autoFocus
                color={"primary"}
                value={formik?.values?.role}
              >
                {allowedRoles.map((role: ROLES) => (
                  <MenuItem
                    key={role}
                    value={role}
                    color={"primary"}
                    sx={{ backgroundColor: "primary" }}
                  >
                    {role.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
              <ErrorMessage name="role" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="location.city"
                label="City"
                type="text"
                id="location.city"
                value={formik?.values?.location?.city}
              />
              <ErrorMessage
                name="location.city"
                component={StyledFormicError}
              />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="location.state"
                label="State / Province"
                type="text"
                id="location.state"
                value={formik?.values?.location?.state}
              />
              <ErrorMessage
                name="location.state"
                component={StyledFormicError}
              />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="location.country"
                label="Country"
                type="text"
                id="location.country"
                value={formik?.values?.location?.country}
              />
              <ErrorMessage
                name="location.country"
                component={StyledFormicError}
              />

              <Grid
                sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCreateUserModalDismiss}
                >
                  Cancel
                </Button>

                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={30}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Grid>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Modal>
  );
};

export default CreateOrEditUserModal;
