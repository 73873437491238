import * as React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useParams } from "react-router-dom";
import { getCompanyQuotationThunk } from "../../../redux/quotations/thunks";
import { Quotation } from "../../../types/quotation";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { userSelector } from "../../../redux/auth/selectors";
import { decryptCost } from "../../Quotations/utils";

type Props = {
  isDirect: boolean;
};

export default function TopCostPerPartNumber({ isDirect }: Props) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const user = useAppSelector(userSelector);
  const { companyId } = params;

  const year = new Date().getFullYear().toString();

  const [options, setOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderColor: "black",
      borderWidth: 0.1,
      type: "pie",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: isDirect
        ? `Top Cost per Part Number in ${year}`
        : `Top Indirect Cost in ${year}`,

      align: "center",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>${point.y}</b>",
    },
    accessibility: {
      point: {
        valuePrefix: "$",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format:
            '<span style="font-size: 1.2em"><b>{point.name}</b>' +
            "</span><br>" +
            '<span style="opacity: 0.6">${point.y} </span>',
          connectorColor: "#8080807F",
        },
      },
    },
    series: [] as any[],
  });

  useEffect(() => {
    dispatch(getCompanyQuotationThunk({ companyId, year })).then(
      digestAndPopulateData,
    );
  }, [companyId]);

  const digestAndPopulateData = ({ payload }: any) => {
    const costPerMaterial: { [key: string]: number } = {};
    for (const quotation of payload.data as Quotation[]) {
      if (quotation.isDirectMaterial === isDirect) {
        if (quotation.material?.includes(",")) {
          let materials = quotation.material.split(",");
          materials = materials.map(
            (material) => `Part Number: ${material.trim()}`,
          );
          for (const material of materials) {
            const cost = decryptCost(user, quotation.cost);
            if (costPerMaterial[material]) {
              costPerMaterial[material] += cost;
            } else {
              costPerMaterial[material] = cost;
            }
          }
        } else {
          const cost = decryptCost(user, quotation.cost);
          const material = quotation.isDirectMaterial
            ? `Part Number: ${quotation.material}`
            : quotation.material || "";
          if (costPerMaterial[material]) {
            costPerMaterial[material] += cost;
          } else {
            costPerMaterial[material] = cost;
          }
        }
      }
    }
    let data = Object.entries(costPerMaterial).map(([name, y]) => ({
      name,
      y,
    }));
    data = data.sort((a, b) => b.y - a.y);
    data = data.slice(0, 5);

    options.series = [];
    options.series.push({
      name: isDirect ? "Cost Per Part Number" : "Indirect Cost",
      data,
    });

    setOptions({ ...options });
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={true}
    />
  );
}
