import styled from "@emotion/styled";

// @ts-ignore
export const StyledWrapper = styled.div(
  {
    color: "white",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginBottom: 40,
  },
  (props: any) => ({ marginLeft: props.isDrawerOpen ? 240 : 0 }),
);

// @ts-ignore
export const InnerWrapper = styled("div")(() => ({
  padding: "20px",
}));

// @ts-ignore
export const MainContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));
