/**
 * @description Enum containing routes to all pages rendered by react-router
 */
export enum AdminRoutes {
  Companies = "/companies",
  CompanyEdit = "/company/edit/:id",
  Dashboard = "/dashboard/:companyId",
  Login = "/",
  ResetPassword = "/reset",
  QuotationAdd = "/quotation/:companyId/add",
  QuotationEdit = "/quotation/:companyId/edit/:id",
  QuotationList = "/quotation/:companyId/list",
  ResetPasswordRequest = "/reset-request",
  SetPassword = "/verify",
  SignUp = "/signup",
  User = "/users",
  Vendors = "/vendors",
  Partners = "/partners",
}
