import { styled } from "@mui/material";

export const InfoText = styled("p")(({ theme }) => ({
  textAlign: "center",
  margin: 0,
  padding: "15px 0",
  fontSize: 14,
  color: "white",
}));
export const StyledLink = styled("a")(() => ({
  color: "white",
  fontWeight: 600,
  textDecoration: "none",
  cursor: "pointer",
}));
