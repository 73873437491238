import { User } from "../../types/users";
import { AES, enc } from "crypto-ts";
import moment from "moment";

export const decryptCost = (
  user: User | null,
  cost: string | undefined,
): number => {
  if (user?.secretKey && cost) {
    const bytes = AES.decrypt(cost, user?.secretKey);
    const decryptedCost = bytes.toString(enc.Utf8);
    return parseFloat(decryptedCost);
  }
  return 0;
};

export const encryptCost = (
  user: User | null,
  cost: number | undefined,
): string => {
  if (user?.secretKey && cost) {
    return AES.encrypt(cost.toString(), user.secretKey).toString();
  }
  return "";
};

export const formatCamelCase = (key: string) => {
  const formattedKey = key.replace(/[A-Z]/g, (letter) => ` ${letter}`);
  return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
};

export const toLocalIsoString = (date: string | undefined) => {
  console.log(date);
  return moment(date).format("YYYY-MM-DDTHH:mm");
};
