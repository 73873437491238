import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Switch,
  ThemeProvider,
} from "@mui/material";
import theme from "../../theme/theme";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { style, StyledTextView } from "./styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  decryptCost,
  formatCamelCase,
  toLocalIsoString,
} from "../../pages/Quotations/utils";
import { Quotation } from "../../types/quotation";
import { REASON_CODE } from "../../types/quotationRequest";
import { Direction } from "../../types/Direction";
import { useAppSelector } from "../../hooks/useAppSelector";
import { userSelector } from "../../redux/auth/selectors";

export type Props = {
  isPreview: boolean;
  isQuotationRequestOnly?: boolean;
  quotation: Quotation | null;
  confirmQuotationSubmitModal: boolean;
  confirmButtonText?: string;
  handleConfirmQuotationSubmitModalDismiss: () => void;
  handleConfirmQuotationSubmitModalConfirm?: () => void;
};

const PreviewQuotationModal = ({
  isPreview,
  isQuotationRequestOnly = false,
  quotation,
  confirmQuotationSubmitModal,
  confirmButtonText,
  handleConfirmQuotationSubmitModalDismiss,
  handleConfirmQuotationSubmitModalConfirm,
}: Props) => {
  const user = useAppSelector(userSelector);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={confirmQuotationSubmitModal}
        onClose={handleConfirmQuotationSubmitModalDismiss}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" mb={5}>
              Confirm Your Quotation Data Before Submitting
            </Typography>

            <Stack direction="row" spacing={5} mb={5}>
              <StyledTextView
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoFocus
                value={quotation?.quotationRequest?.name}
                inputProps={{ readOnly: true }}
                focused={false}
              />
            </Stack>

            <Grid
              mb={4}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
            >
              <Grid item xs={6}>
                <Stack>
                  <InputLabel shrink>Container(s)*</InputLabel>

                  {quotation?.quotationRequest?.containers?.map(
                    (
                      container: { [key: string]: number | string },
                      index: number,
                    ) => (
                      <Accordion key={index} expanded={false}>
                        <AccordionSummary
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {`${container.type} (${container.width} X ${container.height} X ${container.length})`}
                        </AccordionSummary>
                      </Accordion>
                    ),
                  )}
                </Stack>
              </Grid>
              <Grid item xs={6} mt={1}>
                <StyledTextView
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="weight"
                  label="Total Weight"
                  name="weight"
                  type="number"
                  value={quotation?.quotationRequest?.weight}
                  inputProps={{ readOnly: true }}
                  focused={false}
                />
              </Grid>
            </Grid>

            <Stack direction="row" spacing={5} mb={5}>
              <StyledTextView
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="commodity"
                label="Commodity"
                name="commodity"
                value={quotation?.quotationRequest?.commodity}
                inputProps={{ readOnly: true }}
                focused={false}
              />
            </Stack>

            <Stack direction="row" spacing={5} mb={5}>
              <StyledTextView
                multiline
                variant="standard"
                margin="normal"
                fullWidth
                id="notes"
                label="Notes"
                name="notes"
                value={quotation?.quotationRequest?.notes}
                inputProps={{ readOnly: true }}
                focused={false}
              />
            </Stack>

            <Stack direction="row" spacing={5} mb={5}>
              <StyledTextView
                variant="standard"
                aria-readonly
                margin="normal"
                fullWidth
                id="certificateOfOriginLink"
                label="Certificate Of Origin Link"
                name="certificateOfOriginLink"
                value={quotation?.quotationRequest?.certificateOfOriginLink}
                inputProps={{ readOnly: true }}
                focused={false}
              />
            </Stack>

            <Stack direction="row" spacing={5} mb={5}>
              <StyledTextView
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="readyTime"
                label="Ready Time"
                name="readyTime"
                type="datetime-local"
                value={toLocalIsoString(quotation?.quotationRequest?.readyTime)}
                inputProps={{ readOnly: true }}
                focused={false}
              />

              <StyledTextView
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="needTime"
                label="Need Time"
                name="needTime"
                type="datetime-local"
                value={toLocalIsoString(quotation?.quotationRequest?.needTime)}
                inputProps={{ readOnly: true }}
                focused={false}
              />
            </Stack>

            <Stack direction="row" spacing={5} mb={5}>
              <Box width="100%">
                <FormControlLabel
                  control={
                    <Switch
                      size="medium"
                      name="teamDriversRequest"
                      checked={quotation?.quotationRequest?.teamDriversRequest}
                    />
                  }
                  label="Team Drivers Required?"
                />
              </Box>
              <Box width="100%">
                <FormControlLabel
                  control={
                    <Switch
                      size="medium"
                      name="isInternal"
                      checked={!quotation?.quotationRequest?.isInternal}
                    />
                  }
                  label="Send Request To All Company Vendors?"
                />
              </Box>
            </Stack>

            <Stack direction="column" spacing={5} mb={5}>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      name="isMultiPickup"
                      checked={quotation?.quotationRequest?.isMultiPickup}
                    />
                  }
                  label="Multi Pickup?"
                />

                {quotation?.quotationRequest?.pickupLocations?.map(
                  (loc: { [key: string]: string }, index: number) => (
                    <Accordion key={index} expanded={false}>
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {`${loc.addressLine1}, ${loc.addressLine2} - ${loc.city} (${loc.state}), ${loc.country}`}
                      </AccordionSummary>
                    </Accordion>
                  ),
                )}
              </Stack>
              <Stack>
                <FormControlLabel
                  control={
                    <Switch
                      name="isMultiDelivery"
                      checked={quotation?.quotationRequest?.isMultiDelivery}
                    />
                  }
                  label="Multi Delivery?"
                />

                {quotation?.quotationRequest?.deliveryLocations?.map(
                  (loc: { [key: string]: string }, index: number) => (
                    <Accordion key={index} expanded={false}>
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {`${loc.addressLine1}, ${loc.addressLine2} - ${loc.city} (${loc.state}), ${loc.country}`}
                      </AccordionSummary>
                    </Accordion>
                  ),
                )}
              </Stack>
            </Stack>
          </CardContent>

          {!isQuotationRequestOnly && (
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" mb={5}>
                Check Your Quotation Saved Data
              </Typography>

              <Stack direction="row" spacing={5} mb={5}>
                <StyledTextView
                  variant="standard"
                  margin="normal"
                  type="number"
                  required
                  fullWidth
                  id="cost"
                  label="Cost"
                  name="cost"
                  autoFocus
                  value={decryptCost(user, quotation?.cost)}
                  inputProps={{ readOnly: true }}
                  focused={false}
                />
              </Stack>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
              >
                <Grid item xs={6}>
                  <StyledTextView
                    select
                    variant="standard"
                    required
                    fullWidth
                    id="reason"
                    label="Reason"
                    name="reason"
                    value={quotation?.reason}
                    inputProps={{ readOnly: true }}
                    focused={false}
                  >
                    <MenuItem value={"expedite"}>Expedite</MenuItem>
                    <MenuItem value={"nonContracted"}>
                      Non-contracted Move
                    </MenuItem>
                  </StyledTextView>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={5} mb={5}>
                    <StyledTextView
                      select
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="reasonDescription"
                      name="reasonDescription"
                      label="Reason Code"
                      value={
                        Object.values(REASON_CODE).includes(
                          quotation?.reasonDescription as REASON_CODE,
                        )
                          ? quotation?.reasonDescription
                          : REASON_CODE.OTHER
                      }
                      inputProps={{ readOnly: true }}
                      focused={false}
                    >
                      {Object.values(REASON_CODE).map((type: REASON_CODE) => (
                        <MenuItem key={type} value={type}>
                          {formatCamelCase(type)}
                        </MenuItem>
                      ))}
                    </StyledTextView>
                    {quotation?.reasonDescription === REASON_CODE.OTHER && (
                      <StyledTextView
                        variant="standard"
                        margin="normal"
                        type="text"
                        required
                        fullWidth
                        id="otherReasonCode"
                        label="Other Reason Code"
                        name="otherReasonCode"
                        value={
                          !Object.values(REASON_CODE).includes(
                            quotation?.reasonDescription as REASON_CODE,
                          ) && quotation?.reasonDescription
                            ? quotation?.reasonDescription
                            : ""
                        }
                        inputProps={{ readOnly: true }}
                        focused={false}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={5} mb={5}>
                <StyledTextView
                  select
                  variant="standard"
                  required
                  fullWidth
                  id="direction"
                  label="Direction"
                  name="direction"
                  value={quotation?.direction}
                  inputProps={{ readOnly: true }}
                  focused={false}
                >
                  <MenuItem value={Direction.INBOUND}>
                    {Direction.INBOUND.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={Direction.OUTBOUND}>
                    {Direction.OUTBOUND.toUpperCase()}
                  </MenuItem>
                </StyledTextView>

                <StyledTextView
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="pointOfContact"
                  label="Supplier or Consignee Name"
                  name="pointOfContact"
                  autoFocus
                  value={quotation?.pointOfContact}
                  inputProps={{ readOnly: true }}
                  focused={false}
                />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <StyledTextView
                  select
                  variant="standard"
                  required
                  fullWidth
                  id="isDirectMaterial"
                  label="Material Type"
                  name="isDirectMaterial"
                  value={quotation?.isDirectMaterial ? "direct" : "indirect"}
                  inputProps={{ readOnly: true }}
                  focused={false}
                >
                  <MenuItem value={"direct"}>Direct</MenuItem>
                  <MenuItem value={"indirect"}>Indirect</MenuItem>
                </StyledTextView>

                <StyledTextView
                  select={!quotation?.isDirectMaterial}
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="material"
                  label={
                    quotation?.isDirectMaterial
                      ? "Part Number(s) (comma separated if multiple)"
                      : "Indirect Relation"
                  }
                  name="material"
                  autoFocus
                  value={quotation?.material}
                  inputProps={{ readOnly: true }}
                  focused={false}
                >
                  <MenuItem value={"MRO"}>MRO</MenuItem>
                  <MenuItem value={"engineering"}>Engineering</MenuItem>
                  <MenuItem value={"containerTransfer"}>
                    Container Transfer
                  </MenuItem>
                  <MenuItem value={"project"}>Project</MenuItem>
                  <MenuItem value={"capitalEquipmentTransfer"}>
                    Capital Equipment Transfer
                  </MenuItem>
                </StyledTextView>
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <StyledTextView
                  variant="standard"
                  margin="normal"
                  fullWidth
                  id="projectId"
                  label="Associated Project ID (if any)"
                  name="projectId"
                  value={quotation?.projectId}
                  inputProps={{ readOnly: true }}
                  focused={false}
                />
                <StyledTextView
                  variant="standard"
                  margin="normal"
                  fullWidth
                  id="carrierReference"
                  label="Carrier Reference"
                  name="carrierReference"
                  value={quotation?.carrierReference}
                  inputProps={{ readOnly: true }}
                  focused={false}
                />
              </Stack>
            </CardContent>
          )}
          <CardActions
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Button
              color="secondary"
              size="large"
              variant="contained"
              onClick={handleConfirmQuotationSubmitModalDismiss}
            >
              {isPreview ? "CLOSE" : "CANCEL"}
            </Button>

            {!isPreview && (
              <Button
                color="success"
                size="large"
                variant="contained"
                onClick={handleConfirmQuotationSubmitModalConfirm}
              >
                {confirmButtonText || "ACCEPT"}
              </Button>
            )}
          </CardActions>
        </Card>
      </Modal>
    </ThemeProvider>
  );
};

export default PreviewQuotationModal;
