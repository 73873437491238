import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config/api";
import { API_ROUTES } from "../../config/apiRoutes";

import {
  IChangePassword,
  ILoginCredentials,
  ILoginResponse,
  IResetPasswordCredentials,
  IResetPasswordRequestCredentials,
} from "../../types/auth";

import { Amplify } from "aws-amplify";
import {
  confirmResetPassword,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updatePassword,
  signUp,
} from "aws-amplify/auth";

import { User } from "../../types/users";
import { LocalStorage } from "../../utils/LocalStorage";
import { LoginResponseType, LoginSteps } from "../../helpers/constants";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
      userPoolId: process.env.REACT_APP_POOL_ID || "",
    },
  },
});

/**
 * @description Dispatch this thunk to send login data, and receive secret key for 2fa validation
 * @param {ILoginCredentials} reqData - Object containing email and password
 */
export const logInThunk = createAsyncThunk<ILoginResponse, ILoginCredentials>(
  "auth/logInThunk",
  async (reqData, { rejectWithValue }) => {
    try {
      const resp = await signIn({
        username: reqData?.username?.toString() || "",
        password: reqData?.password?.toString() || "",
      });

      if (resp.nextStep.signInStep !== LoginSteps.DONE) {
        return {
          type: LoginResponseType.ACTION_REQUIRED,
          payload: {
            action: resp.nextStep.signInStep,
            payload: { resp, email: reqData?.username?.toString() },
          },
        };
      }
      const session = await fetchAuthSession();
      LocalStorage.setToken(session?.tokens?.accessToken?.toString() || "");
      const { data } = await API.get(
        API_ROUTES.USERS.GET_BY_EMAIL(reqData?.username?.toString() || ""),
      );
      const user = data?.data as User;
      const currentAttributes = await fetchUserAttributes();
      user.secretKey = currentAttributes?.["custom:privateKey"];
      return {
        type: LoginResponseType.SUCCESS,
        payload: user,
      };
    } catch (error: any) {
      await logout();
      console.log(error);
      return rejectWithValue(error);
    }
  },
);

/**
 * @description Dispatch this thunk to send sign in data if existent
 */
export const checkLoggedUserThunk = createAsyncThunk<User, void>(
  "auth/checkLoggedUserThunk",
  async (reqData, { rejectWithValue }) => {
    try {
      let user: User = {} as User;
      const currentUser = await getCurrentUser();
      if (currentUser?.signInDetails?.loginId) {
        const { data } = await API.get(
          API_ROUTES.USERS.GET_BY_EMAIL(
            currentUser?.signInDetails?.loginId || "",
          ),
        );
        const currentAttributes = await fetchUserAttributes();
        user = data?.data as User;
        user.secretKey = currentAttributes?.["custom:privateKey"];
      } else {
        await logout();
        return rejectWithValue("User not authenticated");
      }
      const session = await fetchAuthSession();
      if (session?.tokens?.accessToken) {
        LocalStorage.setToken(session?.tokens?.accessToken?.toString() || "");
      } else {
        await logout();
        return rejectWithValue("User not authenticated");
      }
      return user;
    } catch (error) {
      await logout();
      return rejectWithValue(error);
    }
  },
);

/**
 * @description Dispatch this thunk to send reset password request
 * @param {IResetPasswordRequestCredentials} reqData - Object containing email
 */
export const resetPasswordRequestThunk = createAsyncThunk<
  void,
  IResetPasswordRequestCredentials
>("auth/resetPasswordRequestThunk", async (reqData, { rejectWithValue }) => {
  try {
    await resetPassword({
      username: reqData?.email?.toString() || "",
      options: { clientMetadata: { env: process.env.REACT_APP_ENV || "" } },
    });
  } catch (error) {
    console.log(error);
    return rejectWithValue(error);
  }
});

export const resetPasswordThunk = createAsyncThunk<
  string,
  IResetPasswordCredentials
>("auth/resetPasswordThunk", async (reqData, { rejectWithValue }) => {
  try {
    await confirmResetPassword({
      username: reqData?.username?.toString() || "",
      newPassword: reqData?.password?.toString() || "",
      confirmationCode: reqData?.code?.toString() || "",
    });
    return "success";
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error.message);
  }
});

export const changePasswordThunk = createAsyncThunk<string, IChangePassword>(
  "auth/changePasswordThunk",
  async (reqData, { rejectWithValue }) => {
    try {
      await updatePassword({
        oldPassword: reqData?.password?.toString() || "",
        newPassword: reqData?.newPassword?.toString() || "",
      });
      return "success";
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const logoutThunk = createAsyncThunk<void, void>(
  "auth/logoutThunk",
  async (_, { rejectWithValue }) => {
    try {
      await logout();
    } catch (error: any) {
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const logout = async () => {
  try {
    await signOut({ global: true });
    LocalStorage.clearToken();
  } catch (error: any) {
    console.log(error);
  }
};

export const registerUser = async (username: string, name: string) => {
  try {
    const password = `${
      Math.random().toString(36).slice(2, 9) +
      Math.random().toString(36).slice(2, 9).toUpperCase()
    }!${Math.floor(Math.random() * 100)}`;
    await signUp({
      username,
      password,
      options: {
        userAttributes: {
          name,
        },
      },
    });
  } catch (e: any) {
    if (!e.message.includes("PREVENT_SIGNUP_MESSAGE")) {
      throw e;
    }
  }
};
