import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useParams } from "react-router-dom";
import { getCompanyQuotationThunk } from "../../../redux/quotations/thunks";
import { Quotation } from "../../../types/quotation";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { userSelector } from "../../../redux/auth/selectors";
import { decryptCost } from "../../Quotations/utils";

export default function TotalCost() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const user = useAppSelector(userSelector);
  const { companyId } = params;

  const year = new Date().getFullYear().toString();

  const [options, setOptions] = useState({
    chart: {
      zooming: {
        type: "xy",
      },
      shadow: false,
      borderColor: "black",
      borderWidth: 0.1,
    },
    title: {
      text: `Cost per month in ${year} (TOTAL: ${0})`,
      align: "center",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [] as string[],
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "Cost ($)",
        style: {
          color: "#4693a9",
        },
      },
      labels: {
        format: "{value}",
        style: {
          color: "#4693a9",
        },
      },
    },

    tooltip: {
      shared: true,
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      backgroundColor:
        Highcharts?.defaultOptions?.legend?.backgroundColor || "#FFFFFF3F", // theme
    },
    series: [] as any[],
  });

  const digestAndPopulateData = useCallback(
    ({ payload }: any) => {
      const costPerMonth: { [key: string]: number } = {};
      if (!payload?.data) {
        return;
      }
      let total = 0;
      for (const quotation of payload?.data as Quotation[]) {
        const date = new Date(
          quotation?.createdAt || `${new Date().getFullYear()}-01-01`,
        );
        const month = date.toLocaleString("en", { month: "long" });
        const cost = quotation.cost ? decryptCost(user, quotation.cost) : 0;

        if (!costPerMonth[month]) {
          costPerMonth[month] = 0;
        }
        costPerMonth[month] += cost;
        total += cost;
      }
      options.series = [];
      options.series.push({
        name: "Cost",
        type: "column",
        yAxis: 0,
        data: Object.values(costPerMonth),
        tooltip: {
          valuePrefix: "$",
        },
        color: "#4693a9",
      });
      options.xAxis.categories = Object.keys(costPerMonth);
      options.title.text = `Cost per month in ${year} (TOTAL: ${total})`;
      setOptions({ ...options });
    },
    ["options", "user", "year"],
  );

  useEffect(() => {
    dispatch(getCompanyQuotationThunk({ companyId, year })).then(
      digestAndPopulateData,
    );
  }, [companyId, year]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={true}
    />
  );
}
