import * as Yup from "yup";

export const initialValues = {
  name: "",
  companyDescription: "",
  users: [],
  quotations: [],
};

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
