import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { TABLE_ACTIONS } from "../../helpers/constants";
import { Checkbox, Tooltip } from "@mui/material";
import { ActionItem } from "./types";

export type Props = {
  data: string[];
  selected: boolean;
  handleSelected: any;
  handleActionClicked: any;
  isSelectable: boolean;
  allowedMenuItems: string[];
  extraActionItems: ActionItem[];
};

export default function CustomTableRow({
  data,
  selected,
  handleSelected,
  handleActionClicked,
  isSelectable,
  allowedMenuItems,
  extraActionItems,
}: Props) {
  const defaultActionItems: ActionItem[] = [
    {
      name: TABLE_ACTIONS.EDIT,
      icon: <Edit />,
    },
    {
      name: TABLE_ACTIONS.DELETE,
      icon: <DeleteOutline />,
      color: "error",
    },
    {
      name: TABLE_ACTIONS.VIEW,
      icon: <Visibility />,
      color: "success",
    },
  ];

  const isMenuAllowed = (menuitem: string) => {
    return allowedMenuItems.includes(menuitem);
  };

  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          {isSelectable && (
            <Checkbox
              disableRipple
              checked={selected}
              onChange={handleSelected}
            />
          )}
        </TableCell>
        {data.map((entry) => {
          if (entry !== "actions") {
            return <TableCell key={entry}>{entry}</TableCell>;
          } else {
            return (
              <TableCell key={entry} align="right">
                {[...extraActionItems, ...defaultActionItems].map((item) => {
                  return (
                    isMenuAllowed(item.name) && (
                      <Tooltip title={item.name} key={item.name}>
                        <IconButton
                          aria-label={item.name}
                          key={item.name}
                          onClick={() => {
                            handleActionClicked(item.name);
                          }}
                          color={item.color || "primary"}
                        >
                          {item.icon}
                        </IconButton>
                      </Tooltip>
                    )
                  );
                })}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </>
  );
}
