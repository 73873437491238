import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./alert/slice";
import authSlice from "./auth/slice";
import companiesSlice from "./companies/slice";
import quotationSlice from "./quotations/slice";
import usersSlice from "./users/slice";
import vendorsSlice from "./vendors/slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import partnersSlice from "./partners/slice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export const store = configureStore({
  reducer: {
    [alertSlice.name]: alertSlice.reducer,
    [authSlice.name]: persistedAuthReducer,
    [companiesSlice.name]: companiesSlice.reducer,
    [quotationSlice.name]: quotationSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [vendorsSlice.name]: vendorsSlice.reducer,
    [partnersSlice.name]: partnersSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const Persistor = persistStore(store);
