import { createSlice } from "@reduxjs/toolkit";
import { hideNotificationThunk, showNotificationThunk } from "./thunks";
import { NotificationSeverity } from "../../helpers/constants";

interface IState {
  showAlert: boolean;
  message: string;
  severity: "success" | "error" | "info" | "warning" | undefined;
}

const initialState: IState = {
  showAlert: false,
  message: "Heads up!",
  severity: NotificationSeverity.info,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(showNotificationThunk.fulfilled, (state, { payload }) => {
        state.showAlert = true;
        state.message = payload.message;
        state.severity = payload.severity;
      })
      .addCase(hideNotificationThunk.fulfilled, (state, { payload }) => {
        state.showAlert = false;
      }),
});

export default alertSlice;
