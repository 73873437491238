import * as Yup from "yup";
import { Vendor } from "../../types/vendor";

export type Props = {
  createOrEditVendorModal: boolean;
  handleCreateVendorModalDismiss: () => void;
  handleCreateOrEditVendorChange: (vendor?: Vendor) => void;
  isEditing?: boolean;
  vendorToEdit?: Vendor;
};

export const initialValues = {
  name: "",
  email: "",
  phone: "",
  pointOfContact: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    phone: Yup.string().required("This field is required"),
    pointOfContact: Yup.string().required("This field is required"),
  });
