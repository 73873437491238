import * as React from "react";
import { useState } from "react";
import { InnerWrapper, MainContainer, StyledWrapper } from "./styles";
import { ThemeProvider } from "@mui/material/styles";

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Header from "../Header";
import Footer from "../Footer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import InventoryIcon from "@mui/icons-material/Inventory";
import HandshakeIcon from "@mui/icons-material/Handshake";
import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useAppSelector";
import { userSelector } from "../../redux/auth/selectors";
import { ROLES } from "../../types/users";

const drawerWidth = 240;
const Container: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const user = useAppSelector(userSelector);

  const menuList = (companyId: string) => [
    {
      title: "Companies",
      icon: <BusinessIcon color={"primary"} />,
      path: "/companies",
      roles: [ROLES.ADMIN],
    },
    {
      title: "Partners",
      icon: <HandshakeIcon color={"primary"} />,
      path: "/partners",
      roles: [ROLES.ADMIN],
    },
    {
      title: "Users",
      icon: <PeopleIcon color={"primary"} />,
      path: "/users",
      roles: [ROLES.ADMIN],
    },
    {
      title: "Vendors",
      icon: <InventoryIcon color={"primary"} />,
      path: "/vendors",
      roles: [ROLES.ADMIN],
    },

    {
      title: "Dashboard",
      icon: <DashboardIcon color={"primary"} />,
      path: `/dashboard/${companyId}`,
      roles: [ROLES.MANAGER, ROLES.COORDINATOR],
    },
    {
      title: "Quotations",
      icon: <ListIcon color={"primary"} />,
      path: `/quotation/${companyId}/list`,
      roles: [ROLES.MANAGER, ROLES.COORDINATOR],
    },
    {
      title: "Manage Company",
      icon: <BusinessIcon color={"primary"} />,
      path: `/company/edit/${companyId}`,
      roles: [ROLES.MANAGER],
    },
  ];

  const getMenu = () => {
    return menuList(user?.company?.id || "").filter((entry) =>
      entry.roles.includes(user?.role as ROLES),
    );
  };

  const handleDrawerMenuOpen = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <MainContainer>
        <Header handleDrawerMenuOpen={handleDrawerMenuOpen} />
        <Drawer
          sx={{
            position: "absolute",
            width: drawerWidth,
            flexShrink: 1,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              marginTop: "64px",
              marginBottom: "64px",
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={menuIsOpen}
        >
          <Divider />
          <List>
            {getMenu().map((entry, index) => (
              <ListItem key={entry.title} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(entry.path);
                  }}
                >
                  <ListItemIcon>{entry.icon}</ListItemIcon>
                  <ListItemText primary={entry.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>

        <StyledWrapper isDrawerOpen={menuIsOpen}>
          <InnerWrapper>{children}</InnerWrapper>
        </StyledWrapper>

        <Footer />
      </MainContainer>
    </ThemeProvider>
  );
};

export default Container;
