import * as Yup from "yup";
import { Partner } from "../../types/partner";

export type Props = {
  createOrEditPartnerModal: boolean;
  handleCreatePartnerModalDismiss: () => void;
  handleCreateOrEditPartnerChange: (partner?: Partner) => void;
  isEditing?: boolean;
  partnerToEdit?: Partner;
};

export const initialValues = {
  name: "",
  email: "",
  phone: "",
  pointOfContact: "",
};

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required"),
    phone: Yup.string().required("This field is required"),
    pointOfContact: Yup.string().required("This field is required"),
  });
