import * as React from "react";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ILoginCredentials } from "../../types/auth";
import { checkLoggedUserThunk, logInThunk } from "../../redux/auth/thunks";
import { authStatusSelector, userSelector } from "../../redux/auth/selectors";
import { useAppSelector } from "../../hooks/useAppSelector";
import { StyledFormicError } from "../../modals/ResetPasswordModal/styles";
import { Backdrop, Card, CardContent, CardMedia } from "@mui/material";
import theme from "../../theme/theme";
import CircularProgress from "@mui/material/CircularProgress";
import { Navigate } from "react-router-dom";
import {
  LoginSteps,
  Messages,
  NotificationSeverity,
} from "../../helpers/constants";
import { showNotificationThunk } from "../../redux/alert/thunks";
import Stack from "@mui/material/Stack";

export default function SignInSide() {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(authStatusSelector);
  const loginError = statuses["auth/logInThunk"].error;
  const loginSuccess = statuses["auth/logInThunk"].success;
  const [loading, setLoading] = useState(false);
  const action = statuses["auth/logInThunk"].action;

  useEffect(() => {
    dispatch(checkLoggedUserThunk());
  }, [dispatch]);

  if (loginSuccess && action) {
    switch (action) {
      case LoginSteps.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED:
        return <Navigate to="/verify" />;
      case LoginSteps.RESET_PASSWORD:
        break;
      default:
      // return <Navigate to="/login" />;
    }
  }

  const handleLogin = async (values: ILoginCredentials) => {
    setLoading(true);
    await dispatch(logInThunk(values));
    setLoading(false);
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = {
      username: data.get("email"),
      password: data.get("password"),
    };
    await handleLogin(values);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          pt: 10,
          height: "100vh",
          backgroundImage: "url('/images/bg.webp')",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <CssBaseline />

        <Card
          sx={{
            maxWidth: 345,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            sx={{
              width: "80%",
              height: 142,
              mt: 2,
            }}
            image="/images/logo.png"
            title="green iguana"
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography component="h1" variant="h6">
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                {loginError && (
                  <StyledFormicError>
                    Email or password is incorrect
                  </StyledFormicError>
                )}
              </Grid>
              <Button
                disabled={loading}
                fullWidth
                variant="text"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => window.location.replace("/reset-request")}
              >
                Reset Password
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Stack direction="column" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="subtitle2" noWrap>
            Signing In...
          </Typography>
        </Stack>
      </Backdrop>
    </ThemeProvider>
  );
}
