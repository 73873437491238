export enum STATUS_ENUM {
  PENDING = "PENDING",
  FULFILLED = "FULFILLED",
  REJECTED = "REJECTED",
}

export interface IStatus {
  success: boolean;
  pending: boolean;
  error?: string | null;
  action?: string;
}

export interface IStoreStatuses {
  [key: string]: IStatus;
}

export interface IResetPassword {
  email: string;
  code: string;
}
