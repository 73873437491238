import { createSelector } from "reselect";
import { RootState } from "..";

const quotationsState = (state: RootState) => state.quotations;

/**
 * @description Get list of quotations available on current page (pagination is used)
 */
export const quotationsSelector = createSelector(
  quotationsState,
  ({ quotations }) => quotations,
);

/**
 * @description Get data of selected quotation
 */
export const selectedQuotationSelector = createSelector(
  quotationsState,
  ({ selectedQuotation }) => selectedQuotation,
);

/**
 * @description Get statuses of auth slice
 */
export const quotationsStatusSelector = createSelector(
  quotationsState,
  ({ statuses }) => statuses,
);
