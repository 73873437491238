import * as Yup from "yup";
import { CONTAINER_TYPE } from "../../../types/quotationRequest";

export const initialValues = {
  name: "",
  containers: [],
  weight: 0,
  commodity: "",
  notes: "",
  certificateOfOriginLink: "",
  readyTime: new Date().toISOString().substring(0, 16),
  needTime: new Date().toISOString().substring(0, 16),
  isMultiPickup: false,
  pickupLocations: [],
  isMultiDelivery: false,
  deliveryLocations: [],
  teamDriversRequest: false,
  isInternal: false,
  sendToAll: true,
  containerToAdd: {
    width: 0,
    height: 0,
    length: 0,
    type: CONTAINER_TYPE.BOXES_OR_CARTONS,
  },
  pickupLocToAdd: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  },
  deliveryLocToAdd: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  },
};

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    containers: Yup.array()
      .of(
        Yup.object({
          type: Yup.string().required("This field is required"),
        }),
      )
      .min(1),
  });
