import { createSlice } from "@reduxjs/toolkit";
import { IStoreStatuses, STATUS_ENUM } from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import {
  createQuotationThunk,
  getAllCompanyQuotationsThunk,
  getQuotationByIdThunk,
  deleteQuotationThunk,
  updateQuotationThunk,
  submitForManagersApproval,
} from "./thunks";
import { Quotation } from "../../types/quotation";

interface IState {
  quotations: Quotation[] | null;
  selectedQuotation: Quotation | null;
  statuses: IStoreStatuses;
}

const initialState: IState = {
  quotations: null,
  selectedQuotation: null,
  statuses: StatusGenerator.generateStatuses([
    createQuotationThunk.typePrefix,
    getAllCompanyQuotationsThunk.typePrefix,
    getQuotationByIdThunk.typePrefix,
    deleteQuotationThunk.typePrefix,
    updateQuotationThunk.typePrefix,
  ]),
};

const quotationsSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    setSelectedQuotation: (state, action) => {
      state.selectedQuotation = action.payload.data ?? null;
    },
    setQuotations: (state, action) => {
      state.quotations = action.payload.data ?? null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createQuotationThunk.pending, (state) => {
        state.statuses[createQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(createQuotationThunk.rejected, (state, { error }) => {
        state.statuses[createQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(createQuotationThunk.fulfilled, (state, { payload }) => {
        state.statuses[createQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedQuotation = payload;
      })
      .addCase(getAllCompanyQuotationsThunk.pending, (state) => {
        state.statuses[getAllCompanyQuotationsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllCompanyQuotationsThunk.rejected, (state, { error }) => {
        state.statuses[getAllCompanyQuotationsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllCompanyQuotationsThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllCompanyQuotationsThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.quotations = payload;
      })
      .addCase(getQuotationByIdThunk.pending, (state) => {
        state.statuses[getQuotationByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getQuotationByIdThunk.rejected, (state, { error }) => {
        state.statuses[getQuotationByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getQuotationByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getQuotationByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedQuotation = payload.data;
      })
      .addCase(updateQuotationThunk.pending, (state) => {
        state.statuses[updateQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updateQuotationThunk.rejected, (state, { error }) => {
        state.statuses[updateQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updateQuotationThunk.fulfilled, (state, { payload }) => {
        state.statuses[updateQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedQuotation = payload;
      })
      .addCase(deleteQuotationThunk.pending, (state) => {
        state.statuses[deleteQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteQuotationThunk.rejected, (state, { error }) => {
        state.statuses[deleteQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteQuotationThunk.fulfilled, (state) => {
        state.statuses[deleteQuotationThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      })
      .addCase(submitForManagersApproval.pending, (state) => {
        state.statuses[submitForManagersApproval.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(submitForManagersApproval.rejected, (state, { error }) => {
        state.statuses[submitForManagersApproval.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(submitForManagersApproval.fulfilled, (state) => {
        state.statuses[submitForManagersApproval.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default quotationsSlice;
