import styled from "@emotion/styled";

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 417,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "12px",
  boxShadow: "0px 4px 20px rgba(60, 133, 191, 0.18)",
  p: 4,
  textAlign: "center",
};

export const StyledTitle = styled("p")(() => ({
  fontSize: 24,
  fontWeight: "500",
  display: "flex",
  justifyContent: "center",
  marginBottom: 60,
}));

export const StyledText = styled("p")(() => ({
  fontSize: 20,
  fontWeight: "800",
  display: "flex",
  justifyContent: "center",
  marginBottom: 60,
}));
