import React, { useEffect } from "react";
import { vendorsSelector } from "../../../redux/vendors/selectors";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NotificationSeverity } from "../../../helpers/constants";
import Container from "../../../components/Container";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  deleteVendorThunk,
  getAllVendorsThunk,
} from "../../../redux/vendors/thunks";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import VendorsTable from "../components/VendorsTable";

export default function VendorsList() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllVendorsThunk());
  }, [dispatch]);

  const vendors = useAppSelector(vendorsSelector);

  const handleCreateOrUpdateVendor = () => {
    dispatch(getAllVendorsThunk());
  };

  const handleDelete = async (vendorIdToDelete: string) => {
    if (vendorIdToDelete === "") {
      return;
    }
    const result = await dispatch(deleteVendorThunk(vendorIdToDelete));
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );

    dispatch(getAllVendorsThunk());
  };

  return (
    <Container>
      <VendorsTable
        title={"Vendors"}
        extraButtons={[]}
        vendors={vendors}
        handleCreateOrUpdateVendor={handleCreateOrUpdateVendor}
        handleDelete={handleDelete}
      />
    </Container>
  );
}
