import { createSelector } from "reselect";
import { RootState } from "..";

const partnersState = (state: RootState) => state.partners;

/**
 * @description Get list of partners available on current page (pagination is used)
 */
export const partnersSelector = createSelector(
  partnersState,
  ({ partners }) => partners,
);

/**
 * @description Get data of selected partner
 */
export const selectedPartnerSelector = createSelector(
  partnersState,
  ({ selectedPartner }) => selectedPartner,
);

/**
 * @description Get statuses of auth slice
 */
export const partnersStatusSelector = createSelector(
  partnersState,
  ({ statuses }) => statuses,
);
