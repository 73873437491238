import React from "react";
import { InfoText, StyledLink } from "./styles";
import Paper from "@mui/material/Paper";

const Footer: React.FC = () => {
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#333333",
        borderRadius: 0,
      }}
      elevation={3}
    >
      <InfoText>
        {`Copyright © ${new Date().getFullYear()} `}
        <StyledLink
          href="https://www.purplelogistics.ca/"
          target="_blank"
          rel="noopener"
        >
          Violet Space LLC
        </StyledLink>
        . All Rights Reserved
      </InfoText>
    </Paper>
  );
};

export default Footer;
