import * as React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useParams } from "react-router-dom";
import { getCompanyQuotationThunk } from "../../../redux/quotations/thunks";
import { Quotation } from "../../../types/quotation";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { userSelector } from "../../../redux/auth/selectors";
import { decryptCost } from "../../Quotations/utils";
import { Direction } from "../../../types/Direction";

type Props = {
  direction: string;
};

export default function TopCostPerPointOfContact({ direction }: Props) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const user = useAppSelector(userSelector);
  const { companyId } = params;

  const year = new Date().getFullYear().toString();

  const [options, setOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      type: "pie",
      shadow: false,
      borderColor: "black",
      borderWidth: 0.1,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `Top Cost per ${direction === Direction.INBOUND ? "Supplier" : "Consignee"} in ${year}`,
      align: "center",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>${point.y}</b>",
    },
    accessibility: {
      point: {
        valuePrefix: "$",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format:
            '<span style="font-size: 1.2em"><b>{point.name}</b>' +
            "</span><br>" +
            '<span style="opacity: 0.6">${point.y} </span>',
          connectorColor: "#8080807F",
        },
      },
    },
    series: [] as any[],
  });

  const digestAndPopulateData = ({ payload }: any) => {
    const costPerPOC: { [key: string]: number } = {};
    for (const quotation of payload.data as Quotation[]) {
      if (quotation.direction === direction) {
        const cost = decryptCost(user, quotation.cost);
        const pointOfContact = quotation.pointOfContact || "";
        if (costPerPOC[pointOfContact]) {
          costPerPOC[pointOfContact] += cost;
        } else {
          costPerPOC[pointOfContact] = cost;
        }
      }
    }
    let data = Object.entries(costPerPOC).map(([name, y]) => ({
      name,
      y,
    }));
    data = data.sort((a, b) => b.y - a.y);
    data = data.slice(0, 5);

    options.series = [];
    options.series.push({
      name: `Cost Per ${direction === Direction.INBOUND ? "Supplier" : "Consignee"}`,
      data,
    });

    setOptions({ ...options });
  };

  useEffect(() => {
    dispatch(getCompanyQuotationThunk({ companyId, year })).then(
      digestAndPopulateData,
    );
  }, [companyId, year]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={true}
    />
  );
}
