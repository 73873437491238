import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PlusOne from "@mui/icons-material/PlusOne";
import { quotationsSelector } from "../../../redux/quotations/selectors";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  NotificationSeverity,
  TABLE_ACTIONS,
} from "../../../helpers/constants";
import Container from "../../../components/Container";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  deleteQuotationThunk,
  getAllCompanyQuotationsThunk,
} from "../../../redux/quotations/thunks";
import ConfirmActionModal from "../../../modals/ConfirmActionModal/ConfirmActionModal";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../../components/Table/CustomTable";
import { userSelector } from "../../../redux/auth/selectors";
import { ROLES } from "../../../types/users";
import PreviewQuotationModal from "../../../modals/PreviewQuotationModal/PreviewQuotationModal";
import { Quotation, STATUSES } from "../../../types/quotation";
import moment from "moment/moment";
import { decryptCost } from "../utils";

export default function QuotationsList() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [deleteQuotationModal, setDeleteQuotationModal] = useState(false);
  const [quotationIdToDelete, setQuotationIdToDelete] = useState("");
  const [quotationToView, setQuotationToView] = useState({} as Quotation);
  const [showPreviewQuotationModal, setShowPreviewQuotationModal] =
    useState(false);
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);

  const { companyId } = params;

  useEffect(() => {
    dispatch(getAllCompanyQuotationsThunk({ companyId }));
  }, [dispatch]);

  const quotations = useAppSelector(quotationsSelector);

  const handleActionClick = (action: TABLE_ACTIONS, id: string) => {
    switch (action) {
      case TABLE_ACTIONS.EDIT:
        const quotationToEdit = quotations?.find(
          (quotation) => quotation.id === id,
        );
        if (quotationToEdit) {
          navigate(`/quotation/${companyId}/edit/${id}`);
        }
        break;
      case TABLE_ACTIONS.DELETE:
        setQuotationIdToDelete(id);
        setDeleteQuotationModal(true);
        break;
      case TABLE_ACTIONS.VIEW:
        const quotationToView = quotations?.find((q) => q.id === id);
        if (quotationToView) {
          setQuotationToView(quotationToView);
          setShowPreviewQuotationModal(true);
        }
        break;
      default:
        break;
    }
  };

  const navigateToCreateQuotation = () => {
    navigate(`/quotation/${companyId}/add`);
  };

  const handleDelete = async () => {
    if (quotationIdToDelete === "") {
      return;
    }
    const result = await dispatch(deleteQuotationThunk(quotationIdToDelete));
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );

    dispatch(getAllCompanyQuotationsThunk({ companyId }));
    setQuotationIdToDelete("");
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography color="primary" variant="h4">
          Quotations
        </Typography>

        {[ROLES.MANAGER, ROLES.COORDINATOR].includes(user?.role as ROLES) && (
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToCreateQuotation}
            startIcon={<PlusOne />}
          >
            Request a Quotation
          </Button>
        )}
      </Stack>

      <CustomTable
        entities={quotations}
        handleActionClick={handleActionClick}
        fieldToFilter={"name"}
        columns={[
          {
            id: "name",
            label: "Name",
            value: "name",
          },

          {
            id: "status",
            label: "Status",
            value: (quotation: Quotation) =>
              quotation?.declineReason && quotation?.declineReason !== ""
                ? `${quotation?.status} (Decline Reason: ${quotation?.declineReason})`
                : quotation?.status,
          },
          {
            id: "isInternal",
            label: "Is Purple Log Quotation?",
            value: (quotation: Quotation) =>
              quotation?.quotationRequest?.isInternal ? "YES" : "NO",
          },
          {
            id: "pickup",
            label: "Pickup Location",
            value: (quotation: Quotation) =>
              quotation?.quotationRequest?.pickupLocations?.length || 0 > 0
                ? `${quotation?.quotationRequest?.pickupLocations[0].city} - ${quotation?.quotationRequest?.pickupLocations[0].state}`
                : "",
          },
          {
            id: "delivery",
            label: "Delivery Location",
            value: (quotation: Quotation) =>
              quotation?.quotationRequest?.deliveryLocations?.length || 0 > 0
                ? `${quotation?.quotationRequest?.deliveryLocations[0].city} - ${quotation?.quotationRequest?.deliveryLocations[0].state}`
                : "",
          },
          {
            id: "readyTime",
            label: "Ready Time",
            value: (quotation: Quotation) =>
              moment(quotation?.quotationRequest?.readyTime).format(
                "MM/DD/YYYY",
              ) || "",
          },
          {
            id: "carrierReference",
            label: "Carrier Reference Number",
            value: "carrierReference",
          },

          {
            id: "cost",
            label: "Cost",
            value: (quotation: Quotation) => decryptCost(user, quotation.cost),
          },

          { id: "actions", value: "" },
        ]}
        isSelectable={false}
        allowedMenuItems={[
          TABLE_ACTIONS.EDIT,
          TABLE_ACTIONS.DELETE,
          TABLE_ACTIONS.VIEW,
        ]}
        extraActionItems={[]}
      />
      {deleteQuotationModal && (
        <ConfirmActionModal
          title={"Heads up!"}
          message={"Are you sure you want to delete this quotation?"}
          show={deleteQuotationModal}
          onConfirm={handleDelete}
          onDismiss={() => {
            setDeleteQuotationModal(false);
          }}
        />
      )}
      {showPreviewQuotationModal && (
        <PreviewQuotationModal
          isPreview={showPreviewQuotationModal}
          quotation={quotationToView}
          confirmQuotationSubmitModal={showPreviewQuotationModal}
          handleConfirmQuotationSubmitModalDismiss={() => {
            setShowPreviewQuotationModal(false);
          }}
        />
      )}
    </Container>
  );
}
