import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { Quotation, STATUSES } from "../../../types/quotation";
import {
  getQuotationByIdThunk,
  submitForManagersApproval,
  updateQuotationThunk,
} from "../../../redux/quotations/thunks";
import Container from "../../../components/Container";
import Stack from "@mui/material/Stack";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Paper,
} from "@mui/material";
import { selectedQuotationSelector } from "../../../redux/quotations/selectors";
import { getValidationSchema } from "./utils";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { Messages, NotificationSeverity } from "../../../helpers/constants";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import TextField from "@mui/material/TextField";
import { StyledFormicError } from "../../../modals/ResetPasswordModal/styles";
import { Direction } from "../../../types/Direction";
import { userSelector } from "../../../redux/auth/selectors";
import { decryptCost, encryptCost, formatCamelCase } from "../utils";
import { REASON_CODE } from "../../../types/quotationRequest";
import PreviewQuotationModal from "../../../modals/PreviewQuotationModal/PreviewQuotationModal";
import DeclineInternalQuoteModal from "../../../modals/DeclineInternalQuoteModal/DeclineInternalQuoteModal";
import { ROLES } from "../../../types/users";
import ConfirmActionModal from "../../../modals/ConfirmActionModal/ConfirmActionModal";

export default function EditQuotation() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfirmQuotationModal, setShowConfirmQuotationModal] =
    useState(false);
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false);
  const [isQuotationBlocked, setIsQuotationBlocked] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showSubmitToManagersButton, setShowSubmitToManagersButton] =
    useState(false);
  const [showConfirmDeclineModal, setShowConfirmDeclineModal] = useState(false);
  const [
    showRegularQuoteAcceptDeclineButtons,
    setShowRegularQuoteAcceptDeclineButtons,
  ] = useState(false);
  const [showPreviewQuotationModal, setShowPreviewQuotationModal] =
    useState(false);
  useState(false);
  useState(false);
  const quotation = useAppSelector(selectedQuotationSelector);
  const user = useAppSelector(userSelector);
  const { id, companyId } = params;

  useEffect(() => {
    const quotationHasFinished = [
      STATUSES.DECLINED,
      STATUSES.ACCEPTED,
    ].includes(quotation?.status as STATUSES);
    setIsQuotationBlocked(
      quotationHasFinished && user?.role === ROLES.COORDINATOR,
    );
    setShowRegularQuoteAcceptDeclineButtons(
      !quotationHasFinished && user?.role === ROLES.MANAGER,
    );
    const isPending: boolean = [STATUSES.PENDING].includes(
      quotation?.status as STATUSES,
    );

    setShowSubmitToManagersButton(
      isPending && user?.role === ROLES.COORDINATOR,
    );
  }, [user, quotation]);

  useEffect(() => {
    dispatch(getQuotationByIdThunk(id || ""));
  }, [dispatch, id]);

  const handleConfirmQuotationSubmitModalDismiss = async () => {
    setShowConfirmQuotationModal(false);
    setShowPreviewQuotationModal(false);
  };

  const handleConfirmQuotationSubmitModalConfirm = async () => {
    setShowConfirmQuotationModal(false);
    setShowPreviewQuotationModal(false);
    const data = getFormData({ status: STATUSES.ACCEPTED });
    setLoading(true);
    await dispatch(updateQuotationThunk(data));
    setLoading(false);
    dispatch(
      showNotificationThunk({
        message: Messages.successUpdateQuotation,
        severity: NotificationSeverity.success,
      }),
    );
    dispatch(getQuotationByIdThunk(id || ""));
  };

  const handleDecline = async (reason: string) => {
    const data = getFormData({ status: STATUSES.DECLINED });
    data.declineReason = reason;
    setLoading(true);
    await dispatch(updateQuotationThunk(data));
    setLoading(false);
    dispatch(
      showNotificationThunk({
        message: Messages.successUpdateQuotation,
        severity: NotificationSeverity.success,
      }),
    );
    dispatch(getQuotationByIdThunk(id || ""));
  };

  async function onSubmitForManagerApproval() {
    setShowConfirmDeclineModal(false);
    setLoading(true);
    const data = getFormData({});
    await dispatch(updateQuotationThunk(data));
    await dispatch(submitForManagersApproval(id || ""));
    setLoading(false);
    dispatch(
      showNotificationThunk({
        message: Messages.successSubmittingQuotationForManagersApproval,
        severity: NotificationSeverity.success,
      }),
    );
    dispatch(getQuotationByIdThunk(id || ""));
  }

  const handleSave = async (values: Quotation) => {
    setLoading(true);
    await dispatch(updateQuotationThunk(values));
    setLoading(false);
    dispatch(
      showNotificationThunk({
        message: Messages.successUpdateQuotation,
        severity: NotificationSeverity.success,
      }),
    );
    dispatch(getQuotationByIdThunk(id || ""));
  };

  const formik = useFormik({
    initialValues: {
      name: quotation?.name || "",
      cost: decryptCost(user, quotation?.cost) || 0,
      reason: quotation?.reason || "expedite",
      direction: quotation?.direction || Direction.INBOUND,
      pointOfContact: quotation?.pointOfContact || "",
      isDirectMaterial: !!quotation?.isDirectMaterial ? "direct" : "indirect",
      material: quotation?.material || "",
      projectId: quotation?.projectId || "",
      carrierReference: quotation?.carrierReference || "",
      reasonDescription: Object.values(REASON_CODE).includes(
        quotation?.reasonDescription as REASON_CODE,
      )
        ? quotation?.reasonDescription
        : REASON_CODE.OTHER,
      otherReasonCode:
        !Object.values(REASON_CODE).includes(
          quotation?.reasonDescription as REASON_CODE,
        ) && quotation?.reasonDescription
          ? quotation?.reasonDescription
          : "",
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema,
    onSubmit: async (values) => {
      const data = getFormData({
        ...values,
        status: quotation?.status || STATUSES.PENDING,
      });
      await handleSave(data);
    },
  });

  const getFormData = (newValues: any): Quotation => {
    const values: any = Object.assign(formik.values, newValues);
    return {
      id: id || "",
      name: values.name || "",
      cost: encryptCost(user, values.cost),
      reason: values.reason || "",
      direction: values.direction || "",
      pointOfContact: values.pointOfContact || "",
      isDirectMaterial: values.isDirectMaterial === "direct",
      material: values.material || "",
      carrierReference: values.carrierReference || "",
      projectId: values.projectId || "",
      reasonDescription:
        values.reasonDescription === REASON_CODE.OTHER
          ? values.otherReasonCode
          : values.reasonDescription,
      status: values?.status,
    };
  };

  return (
    <Container>
      <FormikProvider value={formik}>
        <Card component="form" onSubmit={formik.handleSubmit}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography color={"primary"} variant="h4">
                {isQuotationBlocked
                  ? `Quotation ${quotation?.status}`
                  : "Finish your Quotation"}
              </Typography>
            </Stack>

            <Box sx={{ mt: 1 }}>
              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoFocus
                  value={formik?.values?.name}
                  inputProps={{ readOnly: true }}
                />
                <ErrorMessage name="name" component={StyledFormicError} />

                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  type="number"
                  required
                  fullWidth
                  id="cost"
                  label="Cost"
                  name="cost"
                  autoFocus
                  value={formik?.values?.cost}
                  inputProps={{ readOnly: isQuotationBlocked }}
                />
                <ErrorMessage name="cost" component={StyledFormicError} />
              </Stack>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
              >
                <Grid item xs={6}>
                  <TextField
                    select
                    variant="standard"
                    onChange={formik.handleChange}
                    required
                    fullWidth
                    id="reason"
                    label="Reason"
                    name="reason"
                    value={formik?.values?.reason}
                    inputProps={{ readOnly: isQuotationBlocked }}
                  >
                    <MenuItem value={"expedite"}>Expedite</MenuItem>
                    <MenuItem value={"nonContracted"}>
                      Non-contracted Move
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column" spacing={5} mb={5}>
                    <TextField
                      select
                      variant="standard"
                      onChange={formik.handleChange}
                      margin="normal"
                      required
                      fullWidth
                      id="reasonDescription"
                      name="reasonDescription"
                      label="Reason Code"
                      autoFocus
                      value={formik?.values?.reasonDescription}
                      inputProps={{ readOnly: isQuotationBlocked }}
                    >
                      {Object.values(REASON_CODE).map((type: REASON_CODE) => (
                        <MenuItem key={type} value={type}>
                          {formatCamelCase(type)}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik?.values?.reasonDescription ===
                      REASON_CODE.OTHER && (
                      <TextField
                        variant="standard"
                        onChange={formik.handleChange}
                        margin="normal"
                        type="text"
                        required
                        fullWidth
                        id="otherReasonCode"
                        label="Other Reason Code"
                        name="otherReasonCode"
                        value={formik?.values?.otherReasonCode}
                        autoFocus
                        inputProps={{ readOnly: isQuotationBlocked }}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  select
                  variant="standard"
                  onChange={formik.handleChange}
                  required
                  fullWidth
                  id="direction"
                  label="Direction"
                  name="direction"
                  value={formik?.values?.direction}
                  inputProps={{ readOnly: isQuotationBlocked }}
                >
                  <MenuItem value={Direction.INBOUND}>
                    {Direction.INBOUND.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={Direction.OUTBOUND}>
                    {Direction.OUTBOUND.toUpperCase()}
                  </MenuItem>
                </TextField>

                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="pointOfContact"
                  label="Supplier or Consignee Name"
                  name="pointOfContact"
                  autoFocus
                  value={formik?.values?.pointOfContact}
                  inputProps={{ readOnly: isQuotationBlocked }}
                />
                <ErrorMessage
                  name="pointOfContact"
                  component={StyledFormicError}
                />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  select
                  variant="standard"
                  onChange={formik.handleChange}
                  required
                  fullWidth
                  id="isDirectMaterial"
                  label="Material Type"
                  name="isDirectMaterial"
                  value={formik?.values?.isDirectMaterial}
                  inputProps={{ readOnly: isQuotationBlocked }}
                >
                  <MenuItem value={"direct"}>Direct</MenuItem>
                  <MenuItem value={"indirect"}>Indirect</MenuItem>
                </TextField>

                <TextField
                  select={formik?.values?.isDirectMaterial === "indirect"}
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="material"
                  label={
                    formik?.values?.isDirectMaterial === "direct"
                      ? "Part Number(s) (comma separated if multiple)"
                      : "Indirect Relation"
                  }
                  name="material"
                  autoFocus
                  value={formik?.values?.material}
                  inputProps={{ readOnly: isQuotationBlocked }}
                >
                  <MenuItem value={"MRO"}>MRO</MenuItem>
                  <MenuItem value={"engineering"}>Engineering</MenuItem>
                  <MenuItem value={"containerTransfer"}>
                    Container Transfer
                  </MenuItem>
                  <MenuItem value={"project"}>Project</MenuItem>
                  <MenuItem value={"capitalEquipmentTransfer"}>
                    Capital Equipment Transfer
                  </MenuItem>
                </TextField>
                <ErrorMessage
                  name="pointOfContact"
                  component={StyledFormicError}
                />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  fullWidth
                  id="projectId"
                  label="Associated Project ID (if any)"
                  name="projectId"
                  value={formik?.values?.projectId}
                  inputProps={{ readOnly: isQuotationBlocked }}
                />
                <ErrorMessage name="projectId" component={StyledFormicError} />

                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  fullWidth
                  id="carrierReference"
                  label="Carrier Reference"
                  name="carrierReference"
                  value={formik?.values?.carrierReference}
                  inputProps={{ readOnly: isQuotationBlocked }}
                />
                <ErrorMessage
                  name="carrierReference"
                  component={StyledFormicError}
                />
              </Stack>
            </Box>
          </CardContent>

          <CardActions
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ mb: 2 }}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Stack direction="row" spacing={1} mb={5}>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => setShowPreviewQuotationModal(true)}
                  >
                    {isQuotationBlocked ? "SUMMARY" : "PREVIEW"}
                  </Button>

                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      disabled={loading || isQuotationBlocked}
                      color="warning"
                      size="small"
                      variant="contained"
                      type="submit"
                    >
                      SAVE
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={30}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              </Grid>
              {showRegularQuoteAcceptDeclineButtons && (
                <Grid item xs={6} sm={6} md={6} justifyContent="flex-end">
                  <Stack
                    direction="row"
                    spacing={1}
                    mb={5}
                    justifyContent="flex-end"
                  >
                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        disabled={loading}
                        color="error"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setShowConfirmDeclineModal(true);
                        }}
                      >
                        {"DECLINE"}
                      </Button>
                    </Box>

                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        disabled={loading}
                        color="success"
                        size="small"
                        variant="contained"
                        onClick={() => setShowConfirmQuotationModal(true)}
                      >
                        {"ACCEPT"}
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={30}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                </Grid>
              )}

              {showSubmitToManagersButton && (
                <Grid item xs={6} sm={6} md={6} justifyContent="flex-end">
                  <Stack
                    direction="row"
                    spacing={1}
                    mb={5}
                    justifyContent="flex-end"
                  >
                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        disabled={loading}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => setShowConfirmSubmitModal(true)}
                      >
                        Submit to Managers Approval
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={30}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </CardActions>
        </Card>
      </FormikProvider>

      {(showConfirmQuotationModal || showPreviewQuotationModal) && (
        <PreviewQuotationModal
          isPreview={showPreviewQuotationModal}
          quotation={quotation}
          confirmQuotationSubmitModal={
            showConfirmQuotationModal || showPreviewQuotationModal
          }
          handleConfirmQuotationSubmitModalDismiss={
            handleConfirmQuotationSubmitModalDismiss
          }
          handleConfirmQuotationSubmitModalConfirm={
            handleConfirmQuotationSubmitModalConfirm
          }
        />
      )}
      {showDeclineModal && (
        <DeclineInternalQuoteModal
          show={showDeclineModal}
          onDismiss={() => setShowDeclineModal(false)}
          onConfirm={handleDecline}
        />
      )}

      {showConfirmDeclineModal && (
        <ConfirmActionModal
          title={"Heads up!"}
          confirmButtonText={"Decline"}
          message={"Are you sure you want to decline this quote?"}
          show={showConfirmDeclineModal}
          onConfirm={() => handleDecline("")}
          onDismiss={() => {
            setShowConfirmDeclineModal(false);
          }}
        />
      )}

      {showConfirmSubmitModal && (
        <ConfirmActionModal
          title={"Heads up!"}
          message={
            "Are you sure you want to submit this quote for manager(s) approval?"
          }
          show={showConfirmSubmitModal}
          onConfirm={() => onSubmitForManagerApproval()}
          onDismiss={() => setShowConfirmSubmitModal(false)}
        />
      )}
    </Container>
  );
}
