import React, { useEffect } from "react";
import { usersSelector } from "../../../redux/users/selectors";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NotificationSeverity } from "../../../helpers/constants";
import Container from "../../../components/Container";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { deleteUserThunk, getAllUsersThunk } from "../../../redux/users/thunks";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import UserTable from "./components/UserTable";
import { ROLES } from "../../../types/users";

export default function UserView() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllUsersThunk());
  }, [dispatch]);

  const users = useAppSelector(usersSelector);

  const handleCreateOrUpdateUser = () => {
    dispatch(getAllUsersThunk());
  };

  const handleDelete = async (userIdToDelete: string) => {
    if (userIdToDelete === "") {
      return;
    }
    const result = await dispatch(deleteUserThunk(userIdToDelete));
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );

    dispatch(getAllUsersThunk());
  };

  return (
    <Container>
      <UserTable
        title="Users"
        extraButtons={[]}
        allowedRoles={[ROLES.ADMIN]}
        users={users}
        handleCreateOrUpdateUser={handleCreateOrUpdateUser}
        handleDelete={handleDelete}
      />
    </Container>
  );
}
