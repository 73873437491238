import * as React from "react";
import { Box, MenuItem, Modal, Stack, ThemeProvider } from "@mui/material";
import { style, StyledText, StyledTitle } from "./styles";
import Button from "@mui/material/Button";
import theme from "../../theme/theme";
import { FormikProvider, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { formatCamelCase } from "../../pages/Quotations/utils";
import { DECLINE_REASON } from "../../types/quotation";

export type Props = {
  show: boolean;
  onDismiss: () => void;
  onConfirm: (reason: string) => void;
};

const DeclineInternalQuoteModal = (props: Props) => {
  const confirm = (reason: string) => {
    props.onConfirm(reason);
    props.onDismiss();
  };

  const formik = useFormik({
    initialValues: { reason: "", otherReasonText: "" },
    enableReinitialize: true,
    onSubmit: (values) => {
      confirm(
        values.reason === DECLINE_REASON.OTHER
          ? values.otherReasonText
          : values.reason,
      );
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.show}
        onClose={props.onDismiss}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledTitle id="modal-modal-title">
            You are declining an Purple Logistics Quote
          </StyledTitle>
          <StyledText id="modal-modal-title">
            Please, describe the reason of your declining to help us improve our
            services.
          </StyledText>

          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <Stack direction="column" spacing={5} mb={8}>
                  <TextField
                    select
                    variant="standard"
                    onChange={formik.handleChange}
                    margin="normal"
                    required
                    fullWidth
                    id="reason"
                    name="reason"
                    label="Reason"
                    autoFocus
                    value={formik?.values?.reason}
                  >
                    {Object.values(DECLINE_REASON).map(
                      (type: DECLINE_REASON) => (
                        <MenuItem key={type} value={type}>
                          {formatCamelCase(type)}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                  {formik?.values?.reason === DECLINE_REASON.OTHER && (
                    <TextField
                      variant="standard"
                      onChange={formik.handleChange}
                      margin="normal"
                      type="text"
                      required
                      fullWidth
                      id="otherReasonText"
                      label="Describe the reason"
                      name="otherReasonText"
                      value={formik?.values?.otherReasonText}
                      autoFocus
                    />
                  )}
                </Stack>

                <Grid
                  sx={{
                    mt: 5,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={props.onDismiss}
                  >
                    Cancel
                  </Button>

                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Box>
            </form>
          </FormikProvider>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default DeclineInternalQuoteModal;
