import React, { useState } from "react";
import ResetPasswordModal from "../../modals/ResetPasswordModal/ResetPasswordModal";
import { logoutThunk } from "../../redux/auth/thunks";
import { AdminRoutes } from "../../config/routes";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { StyledImg } from "./styles";

import {
  AppBar,
  Backdrop,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAppSelector } from "../../hooks/useAppSelector";
import { authStatusSelector, userSelector } from "../../redux/auth/selectors";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

type Actions = {
  handleDrawerMenuOpen: () => void;
};

const Header: React.FC<Actions> = ({ handleDrawerMenuOpen }) => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(authStatusSelector);
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const loading = statuses["auth/logoutThunk"].pending;

  const [passwordModal, setPasswordModal] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handlePasswordModalOpen = () => {
    setPasswordModal(true);
  };

  const handlePasswordModalClose = () => {
    setPasswordModal(false);
  };
  const handleLogout = async () => {
    dispatch(logoutThunk());
    navigate(AdminRoutes.Login);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleSettingsMenuClose = () => {
    setAnchorElUser(null);
  };

  const settings = [
    { title: "Change Password", action: handlePasswordModalOpen },
  ];

  return (
    <AppBar
      position="sticky"
      variant={"elevation"}
      color={"default"}
      sx={{ zIndex: 10000000000000 }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <IconButton
            size="large"
            edge="start"
            color={"primary"}
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleDrawerMenuOpen}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <StyledImg src="/images/logo.png" alt="logo image" />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} size={"large"}>
                <SettingsIcon color={"primary"} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px", zIndex: "1000000000001" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleSettingsMenuClose}
            >
              <MenuItem
                disableRipple
                disableTouchRipple
                sx={{ typography: "body2" }}
              >
                <Box>
                  <Typography variant="subtitle2" noWrap>
                    {user?.firstname}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {user?.email}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    Role: {user?.role?.toUpperCase()}
                  </Typography>
                </Box>
              </MenuItem>

              <Divider sx={{ borderStyle: "dashed", m: 0 }} />

              {settings.map((settingEntry) => (
                <MenuItem
                  key={settingEntry.title}
                  sx={{ typography: "body2", py: 1.5 }}
                  onClick={() => {
                    handleSettingsMenuClose();
                    if (settingEntry.action) {
                      settingEntry.action();
                    }
                  }}
                >
                  <Typography textAlign="center">
                    {settingEntry.title}
                  </Typography>
                </MenuItem>
              ))}

              <Divider sx={{ borderStyle: "dashed", m: 0 }} />

              <MenuItem
                disableRipple
                disableTouchRipple
                onClick={handleLogout}
                sx={{ typography: "body2", color: "error.main", py: 1.5 }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {passwordModal && (
        <ResetPasswordModal
          passwordModal={passwordModal}
          handlePasswordModalClose={handlePasswordModalClose}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Stack direction="column" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="subtitle2" noWrap>
            Signing Out...
          </Typography>
        </Stack>
      </Backdrop>
    </AppBar>
  );
};

export default Header;
