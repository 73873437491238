import { createSlice } from "@reduxjs/toolkit";
import { IStoreStatuses, STATUS_ENUM } from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import {
  createCompanyThunk,
  getAllCompaniesThunk,
  getCompanyByIdThunk,
  deleteCompanyThunk,
  updateCompanyThunk,
} from "./thunks";
import { Company } from "../../types/company";

interface IState {
  companies: Company[] | null;
  selectedCompany: Company | null;
  statuses: IStoreStatuses;
}

const initialState: IState = {
  companies: null,
  selectedCompany: null,
  statuses: StatusGenerator.generateStatuses([
    createCompanyThunk.typePrefix,
    getAllCompaniesThunk.typePrefix,
    getCompanyByIdThunk.typePrefix,
    deleteCompanyThunk.typePrefix,
    updateCompanyThunk.typePrefix,
  ]),
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload.data ?? null;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data ?? null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createCompanyThunk.pending, (state) => {
        state.statuses[createCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(createCompanyThunk.rejected, (state, { error }) => {
        state.statuses[createCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(createCompanyThunk.fulfilled, (state, { payload }) => {
        state.statuses[createCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedCompany = payload;
      })
      .addCase(getAllCompaniesThunk.pending, (state) => {
        state.statuses[getAllCompaniesThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllCompaniesThunk.rejected, (state, { error }) => {
        state.statuses[getAllCompaniesThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllCompaniesThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllCompaniesThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.companies = payload;
      })
      .addCase(getCompanyByIdThunk.pending, (state) => {
        state.statuses[getCompanyByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getCompanyByIdThunk.rejected, (state, { error }) => {
        state.statuses[getCompanyByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getCompanyByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getCompanyByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedCompany = payload.data;
      })
      .addCase(updateCompanyThunk.pending, (state) => {
        state.statuses[updateCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updateCompanyThunk.rejected, (state, { error }) => {
        state.statuses[updateCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updateCompanyThunk.fulfilled, (state, { payload }) => {
        state.statuses[updateCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedCompany = payload;
      })
      .addCase(deleteCompanyThunk.pending, (state) => {
        state.statuses[deleteCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deleteCompanyThunk.rejected, (state, { error }) => {
        state.statuses[deleteCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deleteCompanyThunk.fulfilled, (state) => {
        state.statuses[deleteCompanyThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default companiesSlice;
