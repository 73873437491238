import * as Yup from "yup";

export type Props = {
  createCompanyModal: boolean;
  handleCreateCompanyModalDismiss: () => void;
  handleCreateCompanyChange: () => void;
};

export const initialValues = {
  name: "",
  companyDescription: "",
  users: [],
  quotations: [],
};

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("This field is required"),
    companyDescription: Yup.string().required("This field is required"),
  });
