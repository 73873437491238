import { GenericLocation } from "./location";
import { Company } from "./company";

export interface IUsersListQueryData {
  limit?: number;
  page?: number;
  id?: string;
}

export type User = {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  createdAt: string;
  updatedAt: string;
  role?: ROLES;
  location?: GenericLocation;
  company?: Company;
  secretKey?: string;
};

export enum ROLES {
  COORDINATOR = "coordinator",
  MANAGER = "manager",
  ADMIN = "admin",
}

export type UserAttributes = {
  sub: string;
  email: string;
  name: string;
  "custom:privateKey": string;
};

export type UserLogged = User & { accessToken: string };
