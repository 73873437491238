import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppSelector } from "../hooks/useAppSelector";
import { alertSelector } from "../redux/alert/selectors";
import { hideNotificationThunk } from "../redux/alert/thunks";
import { useAppDispatch } from "../hooks/useAppDispatch";

type NotificationProps = {
  isOpen?: boolean;
  onClose: () => void;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

function Notification({ isOpen, onClose }: NotificationProps) {
  const { showAlert, message, severity } = useAppSelector(alertSelector);
  const dispatch = useAppDispatch();
  const _onClose = () => {
    onClose();
    dispatch(hideNotificationThunk());
  };
  return message ? (
    <Snackbar
      open={showAlert}
      autoHideDuration={6000}
      onClose={_onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ zIndex: 1000000000001 }}
    >
      <Alert onClose={_onClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  ) : null;
}

export default Notification;
