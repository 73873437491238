import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { StyledFormicError } from "../../../modals/ResetPasswordModal/styles";
import { Quotation, STATUSES } from "../../../types/quotation";
import {
  createQuotationThunk,
  getCompanyQuotationCountThunk,
  uploadCertificateThunk,
} from "../../../redux/quotations/thunks";
import Container from "../../../components/Container";
import Stack from "@mui/material/Stack";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Switch,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Add from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { getValidationSchema, initialValues } from "./utils";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { Messages, NotificationSeverity } from "../../../helpers/constants";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import { ShippingContainer } from "../../../types/container";
import { VisuallyHiddenInput } from "./styles";
import { CONTAINER_TYPE } from "../../../types/quotationRequest";
import { formatCamelCase } from "../utils";
import { GenericLocation } from "../../../types/location";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { userSelector } from "../../../redux/auth/selectors";
import PreviewQuotationModal from "../../../modals/PreviewQuotationModal/PreviewQuotationModal";

export default function AddQuotation() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [quotation, setQuotation] = useState({} as Quotation);
  const [showPreviewQuotationModal, setShowPreviewQuotationModal] =
    useState(false);
  const [showRequestQuotationModal, setShowRequestQuotationModal] =
    useState(false);
  const { companyId } = params;
  const user = useAppSelector(userSelector);

  const onLocationRemove = async (
    location: GenericLocation,
    locationsContainer: string,
  ) => {
    switch (locationsContainer) {
      case "pickup":
        await formik.setFieldValue(
          "pickupLocations",
          formik?.values?.pickupLocations.filter(
            (loc: GenericLocation) =>
              loc.addressLine1 !== location.addressLine1 &&
              loc.addressLine2 !== location.addressLine2,
          ),
        );
        break;
      case "delivery":
        await formik.setFieldValue(
          "deliveryLocations",
          formik?.values?.deliveryLocations.filter(
            (loc: GenericLocation) =>
              loc.addressLine1 !== location.addressLine1 &&
              loc.addressLine2 !== location.addressLine2,
          ),
        );
        break;
    }
  };

  const onContainerRemove = async (containerToRemove: {
    [key: string]: number | string;
  }) => {
    await formik.setFieldValue(
      "containers",
      formik?.values?.containers.filter(
        (shippingContainer: ShippingContainer) =>
          shippingContainer.width !== containerToRemove.width &&
          shippingContainer.height !== containerToRemove.height &&
          shippingContainer.length !== containerToRemove.length &&
          shippingContainer.type !== containerToRemove.type,
      ),
    );
  };

  const onLocationAdd = async (
    location: GenericLocation,
    locationsContainer: string,
  ) => {
    let fieldToAdd = "pickupLocToAdd";
    switch (locationsContainer) {
      case "pickup":
        await formik.setFieldValue("pickupLocations", [
          ...formik?.values?.pickupLocations,
          location,
        ]);
        fieldToAdd = "pickupLocToAdd";
        break;
      case "delivery":
        await formik.setFieldValue("deliveryLocations", [
          ...formik?.values?.deliveryLocations,
          location,
        ]);
        fieldToAdd = "deliveryLocToAdd";
        break;
    }
    await formik.setFieldValue(fieldToAdd, {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
    });
  };

  const onContainerAdd = async (container: ShippingContainer) => {
    await formik.setFieldValue("containers", [
      ...formik?.values?.containers,
      container,
    ]);
    await formik.setFieldValue("containerToAdd", {
      width: 0,
      height: 0,
      length: 0,
      type: CONTAINER_TYPE.BOXES_OR_CARTONS,
    });
  };

  const handleCertificateChange = async (event: any) => {
    setUploading(true);
    const result = await dispatch(
      uploadCertificateThunk(event.target.files[0]),
    );

    if (result.meta.requestStatus === "rejected") {
      await dispatch(
        showNotificationThunk({
          message: result.payload.message,
          severity: NotificationSeverity.error,
        }),
      );
    } else {
      formik.values.certificateOfOriginLink = result?.payload?.data?.filePath;
    }
    setUploading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const { type } = await dispatch(createQuotationThunk(quotation));
    const severity =
      type === "quotations/createQuotationThunk/rejected" ? "error" : "success";

    const message =
      type === "quotations/createQuotationThunk/rejected"
        ? Messages.errorCreateQuotation
        : Messages.successCreateQuotation;

    setLoading(false);
    dispatch(
      showNotificationThunk({
        message,
        severity,
      }),
    );
    navigate(`/quotation/${companyId}/list`);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema,
    onSubmit: async (values) => {
      setQuotation(getFormData(values));
      setShowRequestQuotationModal(true);
    },
  });

  const getFormData = (newValues: any): Quotation => {
    const values: any = Object.assign(formik.values, newValues);

    const data = {
      name: values.name || "",
      containers: values.containers,
      weight: values.weight,
      commodity: values.commodity,
      notes: values.notes,
      certificateOfOriginLink: values.certificateOfOriginLink,
      readyTime: values.readyTime,
      needTime: values.needTime,
      isMultiPickup: values.isMultiPickup,
      pickupLocations: values.pickupLocations,
      isMultiDelivery: values.isMultiDelivery,
      deliveryLocations: values.deliveryLocations,
      teamDriversRequest: values.teamDriversRequest,
      isInternal: !values.sendToAll,
    };

    console.log("USER: ", user);

    return {
      name: data.name,
      company: companyId,
      quotationRequest: data,
      owner: user?.id || "",
      status: STATUSES.PENDING,
    };
  };

  useEffect(() => {
    if (companyId === "") {
      return;
    }
    dispatch(getCompanyQuotationCountThunk(companyId || "")).then(
      (result: any) => {
        const name = `Quotation ${parseInt(result?.payload?.data?.count.toString()) + 1}`;
        formik?.setFieldValue("name", name);
      },
    );
  }, [companyId]);

  return (
    <Container>
      <FormikProvider value={formik}>
        <Card component="form" onSubmit={formik.handleSubmit}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography color={"primary"} variant="h4">
                Request a Quotation
              </Typography>
            </Stack>

            <Box sx={{ mt: 1 }}>
              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoFocus
                  value={formik?.values?.name}
                  inputProps={{ readOnly: true }}
                />

                <ErrorMessage name="name" component={StyledFormicError} />
              </Stack>

              <Grid
                mb={4}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}
              >
                <Grid item xs={6}>
                  <Stack>
                    <InputLabel shrink>Container(s)*</InputLabel>

                    {formik?.values?.containers?.map(
                      (
                        container: { [key: string]: number | string },
                        index: number,
                      ) => (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            {`${container.type} (${container.width} X ${container.height} X ${container.length})`}
                          </AccordionSummary>
                          <AccordionDetails>
                            {Object.keys(container)?.map((key: string) => (
                              <TextField
                                key={key}
                                disabled={true}
                                variant="standard"
                                margin="normal"
                                fullWidth
                                label={formatCamelCase(key)}
                                type="text"
                                value={container[key]}
                              />
                            ))}
                          </AccordionDetails>
                          <AccordionActions>
                            <Button
                              onClick={async () =>
                                await onContainerRemove(container)
                              }
                            >
                              Remove Container
                            </Button>
                          </AccordionActions>
                        </Accordion>
                      ),
                    )}

                    <Accordion>
                      <AccordionSummary expandIcon={<Add />}>
                        Add A Container
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          select
                          variant="standard"
                          onChange={formik.handleChange}
                          margin="normal"
                          required
                          fullWidth
                          id="containerType"
                          label="Container Type"
                          name="containerToAdd.type"
                          value={formik?.values?.containerToAdd?.type}
                        >
                          {Object.values(CONTAINER_TYPE).map(
                            (type: CONTAINER_TYPE) => (
                              <MenuItem key={type} value={type}>
                                {formatCamelCase(type)}
                              </MenuItem>
                            ),
                          )}
                        </TextField>
                        <Stack direction="row" spacing={5} mb={5}>
                          <TextField
                            variant="standard"
                            onChange={formik.handleChange}
                            margin="normal"
                            required
                            fullWidth
                            id="width"
                            label="Width (Feet)"
                            name="containerToAdd.width"
                            type="number"
                            value={formik?.values?.containerToAdd?.width}
                          />
                          <TextField
                            variant="standard"
                            onChange={formik.handleChange}
                            margin="normal"
                            required
                            fullWidth
                            id="height"
                            label="Height (Feet)"
                            name="containerToAdd.height"
                            type="number"
                            value={formik?.values?.containerToAdd?.height}
                          />
                          <TextField
                            variant="standard"
                            onChange={formik.handleChange}
                            margin="normal"
                            required
                            fullWidth
                            id="length"
                            label="Length (Feet)"
                            name="containerToAdd.length"
                            type="number"
                            value={formik?.values?.containerToAdd?.length}
                          />
                        </Stack>
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          onClick={() =>
                            onContainerAdd(formik?.values?.containerToAdd)
                          }
                        >
                          Add Container
                        </Button>
                      </AccordionActions>
                    </Accordion>
                    <ErrorMessage
                      name="containers"
                      component={StyledFormicError}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6} mt={1}>
                  <TextField
                    variant="standard"
                    onChange={formik.handleChange}
                    margin="normal"
                    required
                    fullWidth
                    id="weight"
                    label="Total Weight"
                    name="weight"
                    type="number"
                    value={formik?.values?.weight}
                  />
                </Grid>
              </Grid>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="commodity"
                  label="Commodity"
                  name="commodity"
                  value={formik?.values?.commodity}
                />
                <ErrorMessage name="commodity" component={StyledFormicError} />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  multiline
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  fullWidth
                  id="notes"
                  label="Notes"
                  name="notes"
                  value={formik?.values?.notes}
                />
                <ErrorMessage name="notes" component={StyledFormicError} />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  variant="standard"
                  aria-readonly
                  margin="normal"
                  fullWidth
                  id="certificateOfOriginLink"
                  label="Certificate Of Origin Link"
                  name="certificateOfOriginLink"
                  value={formik?.values?.certificateOfOriginLink}
                />

                <Box sx={{ position: "relative" }}>
                  <Button
                    sx={{ width: 450 }}
                    component="label"
                    color="primary"
                    size="large"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Certificate Of Origin
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleCertificateChange}
                    />
                  </Button>
                  {uploading && (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>

                <ErrorMessage
                  name="certificateOfOriginLink"
                  component={StyledFormicError}
                />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="readyTime"
                  label="Ready Time"
                  name="readyTime"
                  type="datetime-local"
                  value={formik?.values?.readyTime}
                />
                <ErrorMessage name="readyTime" component={StyledFormicError} />

                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  id="needTime"
                  label="Need Time"
                  name="needTime"
                  type="datetime-local"
                  value={formik?.values?.needTime}
                />
                <ErrorMessage name="needTime" component={StyledFormicError} />
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <Box width="100%">
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        name="teamDriversRequest"
                        checked={formik?.values?.teamDriversRequest}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Team Drivers Required?"
                  />
                </Box>
                <Box width="100%">
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        name="sendToAll"
                        checked={formik?.values?.sendToAll}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Send Request To All Company Vendors?"
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={5} mb={5}>
                <Stack>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isMultiPickup"
                        checked={formik?.values?.isMultiPickup}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Multi Pickup?"
                  />

                  {formik?.values?.pickupLocations?.map(
                    (loc: { [key: string]: string }, index: number) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {loc.addressLine1}
                        </AccordionSummary>
                        <AccordionDetails>
                          {Object.keys(loc)?.map((key: string) => (
                            <TextField
                              key={key}
                              disabled={true}
                              variant="standard"
                              margin="normal"
                              fullWidth
                              label={formatCamelCase(key)}
                              type="text"
                              value={loc[key]}
                            />
                          ))}
                        </AccordionDetails>
                        <AccordionActions>
                          <Button
                            onClick={async () =>
                              await onLocationRemove(loc, "pickup")
                            }
                          >
                            Remove
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    ),
                  )}
                  {(formik?.values?.pickupLocations?.length === 0 ||
                    formik?.values?.isMultiPickup) && (
                    <Accordion>
                      <AccordionSummary expandIcon={<Add />}>
                        Add A Pickup Address
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Address Line 1"
                          type="text"
                          name="pickupLocToAdd.addressLine1"
                          value={formik?.values?.pickupLocToAdd.addressLine1}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Address Line 2"
                          type="text"
                          name="pickupLocToAdd.addressLine2"
                          value={formik?.values?.pickupLocToAdd.addressLine2}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="City"
                          type="text"
                          name="pickupLocToAdd.city"
                          value={formik?.values?.pickupLocToAdd.city}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="State / Province"
                          type="text"
                          name="pickupLocToAdd.state"
                          value={formik?.values?.pickupLocToAdd.state}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Country"
                          type="text"
                          name="pickupLocToAdd.country"
                          value={formik?.values?.pickupLocToAdd.country}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Zipcode"
                          type="text"
                          name="pickupLocToAdd.zipcode"
                          value={formik?.values?.pickupLocToAdd.zipcode}
                        />
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          onClick={() =>
                            onLocationAdd(
                              formik?.values?.pickupLocToAdd,
                              "pickup",
                            )
                          }
                        >
                          Add
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  )}
                </Stack>
                <Stack>
                  <FormControlLabel
                    control={
                      <Switch
                        name="isMultiDelivery"
                        checked={formik?.values?.isMultiDelivery}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Multi Delivery?"
                  />

                  {formik?.values?.deliveryLocations?.map(
                    (loc: { [key: string]: string }, index: number) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {loc.addressLine1}
                        </AccordionSummary>
                        <AccordionDetails>
                          {Object.keys(loc)?.map((key: string) => (
                            <TextField
                              key={key}
                              disabled={true}
                              variant="standard"
                              margin="normal"
                              fullWidth
                              label={formatCamelCase(key)}
                              type="text"
                              value={loc[key]}
                            />
                          ))}
                        </AccordionDetails>
                        <AccordionActions>
                          <Button
                            onClick={async () =>
                              await onLocationRemove(loc, "delivery")
                            }
                          >
                            Remove
                          </Button>
                        </AccordionActions>
                      </Accordion>
                    ),
                  )}
                  {(formik?.values?.deliveryLocations?.length === 0 ||
                    formik?.values?.isMultiDelivery) && (
                    <Accordion>
                      <AccordionSummary expandIcon={<Add />}>
                        Add A Delivery Address
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Address Line 1"
                          type="text"
                          name="deliveryLocToAdd.addressLine1"
                          value={formik?.values?.deliveryLocToAdd.addressLine1}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Address Line 2"
                          type="text"
                          name="deliveryLocToAdd.addressLine2"
                          value={formik?.values?.deliveryLocToAdd.addressLine2}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="City"
                          type="text"
                          name="deliveryLocToAdd.city"
                          value={formik?.values?.deliveryLocToAdd.city}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="State / Province"
                          type="text"
                          name="deliveryLocToAdd.state"
                          value={formik?.values?.deliveryLocToAdd.state}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Country"
                          type="text"
                          name="deliveryLocToAdd.country"
                          value={formik?.values?.deliveryLocToAdd.country}
                        />
                        <TextField
                          onChange={formik.handleChange}
                          variant="standard"
                          margin="normal"
                          fullWidth
                          label="Zipcode"
                          type="text"
                          name="deliveryLocToAdd.zipcode"
                          value={formik?.values?.deliveryLocToAdd.zipcode}
                        />
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          onClick={() =>
                            onLocationAdd(
                              formik?.values?.deliveryLocToAdd,
                              "delivery",
                            )
                          }
                        >
                          Add
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  )}
                </Stack>
              </Stack>
            </Box>
          </CardContent>

          <CardActions
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
              sx={{ mb: 2 }}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Stack direction="row" spacing={1} mb={5}>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setQuotation(getFormData({}));
                      setShowPreviewQuotationModal(true);
                    }}
                  >
                    PREVIEW
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={6} md={6} justifyContent="flex-end">
                <Stack
                  direction="row"
                  spacing={1}
                  mb={5}
                  justifyContent="flex-end"
                >
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      disabled={loading}
                      color="success"
                      size="small"
                      variant="contained"
                      type="submit"
                    >
                      REQUEST
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={30}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </FormikProvider>

      {(showPreviewQuotationModal || showRequestQuotationModal) && (
        <PreviewQuotationModal
          isPreview={showPreviewQuotationModal}
          isQuotationRequestOnly={true}
          quotation={quotation}
          confirmQuotationSubmitModal={
            showPreviewQuotationModal || showRequestQuotationModal
          }
          confirmButtonText={"Request"}
          handleConfirmQuotationSubmitModalDismiss={() => {
            setShowPreviewQuotationModal(false);
            setShowRequestQuotationModal(false);
          }}
          handleConfirmQuotationSubmitModalConfirm={handleSubmit}
        />
      )}
    </Container>
  );
}
