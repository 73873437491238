import * as React from "react";
import { useState } from "react";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { getValidationSchema, initialValues, Props } from "./config";
import { Box, Modal } from "@mui/material";
import { style, StyledFormicError, StyledText } from "./styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { NotificationSeverity } from "../../helpers/constants";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showNotificationThunk } from "../../redux/alert/thunks";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { createCompanyThunk } from "../../redux/companies/thunks";
import { Company } from "../../types/company";

const CreateCompanyModal = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleCreateCompany = async (reqValues: Company) => {
    setLoading(true);
    const result = await dispatch(createCompanyThunk(reqValues));
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );
    props.handleCreateCompanyModalDismiss();
    props.handleCreateCompanyChange();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqValues = {
        name: values.name,
        companyDescription: values.companyDescription,
      };
      handleCreateCompany(reqValues as Company).then();
    },
  });

  return (
    <Modal
      open={props.createCompanyModal}
      onClose={props.handleCreateCompanyModalDismiss}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <StyledText id="modal-modal-title">{"Create Company"}</StyledText>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                type="text"
                id="name"
                value={formik.values.name}
              />
              <ErrorMessage name="name" component={StyledFormicError} />

              <TextField
                variant="standard"
                onChange={formik.handleChange}
                margin="normal"
                required
                fullWidth
                name="companyDescription"
                label="Description"
                type="text"
                id="companyDescription"
                value={formik.values.companyDescription}
              />
              <ErrorMessage
                name="companyDescription"
                component={StyledFormicError}
              />

              <Grid
                sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={props.handleCreateCompanyModalDismiss}
                >
                  Cancel
                </Button>

                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={30}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Grid>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Modal>
  );
};

export default CreateCompanyModal;
