import React, { useEffect } from "react";
import { partnersSelector } from "../../../redux/partners/selectors";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { NotificationSeverity } from "../../../helpers/constants";
import Container from "../../../components/Container";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  deletePartnerThunk,
  getAllPartnersThunk,
} from "../../../redux/partners/thunks";
import { showNotificationThunk } from "../../../redux/alert/thunks";
import PartnersTable from "../components/PartnersTable";

export default function PartnersList() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllPartnersThunk());
  }, [dispatch]);

  const partners = useAppSelector(partnersSelector);

  const handleCreateOrUpdatePartner = () => {
    dispatch(getAllPartnersThunk());
  };

  const handleDelete = async (partnerIdToDelete: string) => {
    if (partnerIdToDelete === "") {
      return;
    }
    const result = await dispatch(deletePartnerThunk(partnerIdToDelete));
    await dispatch(
      showNotificationThunk({
        message: result.payload.message,
        severity:
          result.meta.requestStatus === "rejected"
            ? NotificationSeverity.error
            : NotificationSeverity.success,
      }),
    );

    dispatch(getAllPartnersThunk());
  };

  return (
    <Container>
      <PartnersTable
        title={"Partners"}
        extraButtons={[]}
        partners={partners}
        handleCreateOrUpdatePartner={handleCreateOrUpdatePartner}
        handleDelete={handleDelete}
      />
    </Container>
  );
}
