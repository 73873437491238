import { GenericLocation } from "./location";
import { ShippingContainer } from "./container";

export interface IQuotationRequestsListQueryData {
  limit?: number;
  page?: number;
  id?: string;
}
export enum CONTAINER_TYPE {
  BOXES_OR_CARTONS = "boxesOrCartons",
  CRATES = "crates",
  PALLETS = "pallets",
  DRUMS_OR_BARRELS = "drumsOrBarrels",
  BAGS_OR_SACKS = "bagsOrSacks",
  TOTES_OR_BINS = "totesOrBins",
}

export enum REASON_CODE {
  INVENTORY_INACCURACY = "inventoryInaccuracy",
  PROCESS_THROUGHPUT_ISSUE = "processThroughputIssue",
  SCHEDULING_ERROR = "schedulingError",
  LOGISTICS_PLANNING_ERROR = "logisticsPlanningError",
  SHIPMENT_ERROR = "shipmentError",
  QUALITY_ISSUES = "qualityIssues",
  CAPACITY_ISSUE = "capacityIssue",
  OTHER = "other",
}

export type QuotationRequest = {
  name: string;
  containers: ShippingContainer[];
  weight: number;
  commodity: string;
  notes?: string;
  certificateOfOriginLink: string;
  readyTime: string;
  needTime: string;
  isMultiPickup: boolean;
  pickupLocations: GenericLocation[];
  isMultiDelivery: boolean;
  deliveryLocations: GenericLocation[];
  teamDriversRequest: boolean;
  isInternal: boolean;
};
