import * as React from "react";
import { Props } from "./config";
import { Box, Modal, Stack, ThemeProvider } from "@mui/material";
import { style, StyledText, StyledTitle } from "./styles";
import Button from "@mui/material/Button";
import theme from "../../theme/theme";

const ConfirmActionModal = (props: Props) => {
  const confirm = () => {
    props.onConfirm();
    props.onDismiss();
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.show}
        onClose={props.onDismiss}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledTitle id="modal-modal-title">{props.title}</StyledTitle>
          <StyledText id="modal-modal-title">{props.message}</StyledText>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            mt={2}
          >
            <Button variant="contained" color="error" onClick={props.onDismiss}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={confirm}>
              {props.confirmButtonText || "Confirm"}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ConfirmActionModal;
