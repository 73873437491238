import { createSlice } from "@reduxjs/toolkit";
import { IStoreStatuses, STATUS_ENUM } from "../../types/statuses";
import { StatusGenerator } from "../../utils/StatusGenerator";
import {
  createPartnerThunk,
  getAllPartnersThunk,
  getPartnerByIdThunk,
  deletePartnerThunk,
  updatePartnerThunk,
} from "./thunks";
import { Partner } from "../../types/partner";

interface IState {
  partners: Partner[] | null;
  selectedPartner: Partner | null;
  statuses: IStoreStatuses;
}

const initialState: IState = {
  partners: null,
  selectedPartner: null,
  statuses: StatusGenerator.generateStatuses([
    createPartnerThunk.typePrefix,
    getAllPartnersThunk.typePrefix,
    getPartnerByIdThunk.typePrefix,
    deletePartnerThunk.typePrefix,
    updatePartnerThunk.typePrefix,
  ]),
};

const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    setSelectedPartner: (state, action) => {
      state.selectedPartner = action.payload.data ?? null;
    },
    setPartners: (state, action) => {
      state.partners = action.payload.data ?? null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createPartnerThunk.pending, (state) => {
        state.statuses[createPartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(createPartnerThunk.rejected, (state, { error }) => {
        state.statuses[createPartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(createPartnerThunk.fulfilled, (state, { payload }) => {
        state.statuses[createPartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedPartner = payload;
      })
      .addCase(getAllPartnersThunk.pending, (state) => {
        state.statuses[getAllPartnersThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getAllPartnersThunk.rejected, (state, { error }) => {
        state.statuses[getAllPartnersThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getAllPartnersThunk.fulfilled, (state, { payload }) => {
        state.statuses[getAllPartnersThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.partners = payload;
      })
      .addCase(getPartnerByIdThunk.pending, (state) => {
        state.statuses[getPartnerByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(getPartnerByIdThunk.rejected, (state, { error }) => {
        state.statuses[getPartnerByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(getPartnerByIdThunk.fulfilled, (state, { payload }) => {
        state.statuses[getPartnerByIdThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedPartner = payload.data;
      })
      .addCase(updatePartnerThunk.pending, (state) => {
        state.statuses[updatePartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(updatePartnerThunk.rejected, (state, { error }) => {
        state.statuses[updatePartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(updatePartnerThunk.fulfilled, (state, { payload }) => {
        state.statuses[updatePartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
        state.selectedPartner = payload;
      })
      .addCase(deletePartnerThunk.pending, (state) => {
        state.statuses[deletePartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.PENDING);
      })
      .addCase(deletePartnerThunk.rejected, (state, { error }) => {
        state.statuses[deletePartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.REJECTED, error.message);
      })
      .addCase(deletePartnerThunk.fulfilled, (state) => {
        state.statuses[deletePartnerThunk.typePrefix] =
          StatusGenerator.setStatus(STATUS_ENUM.FULFILLED);
      }),
});

export default partnersSlice;
