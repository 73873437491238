import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordThunk } from "../../redux/auth/thunks";
import { useAppSelector } from "../../hooks/useAppSelector";
import { authStatusSelector } from "../../redux/auth/selectors";
import { getValidationSchema, initialValues } from "./config";
import { IResetPasswordCredentials } from "../../types/auth";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Card, CardContent, CardMedia } from "@mui/material";
import theme from "../../theme/theme";
import { StyledFormicError } from "../../modals/ResetPasswordModal/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { Messages, NotificationSeverity } from "../../helpers/constants";
import Notification from "../../utils/Notification";
import { showNotificationThunk } from "../../redux/alert/thunks";

const ResetPasswordWrapper: React.FC = () => {
  const statuses = useAppSelector(authStatusSelector);
  const navigate = useNavigate();
  const error = statuses["auth/resetPasswordThunk"].error;
  const loading = statuses["auth/resetPasswordThunk"].pending;
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: IResetPasswordCredentials) => {
    dispatch(resetPasswordThunk(values));
    dispatch(
      showNotificationThunk({
        message: Messages.successPassword,
        severity: NotificationSeverity.success,
      }),
    );
    navigate("/");
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema,
    onSubmit: (values) => {
      const data = {
        password: values.password,
        username: searchParams.get("email") || "",
        code: searchParams.get("code") || "",
      };
      handleSubmit(data);
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          pt: 10,
          height: "100vh",
          backgroundImage: "url('/images/bg.webp')",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <CssBaseline />

        <Card
          sx={{
            maxWidth: 345,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            sx={{
              width: "80%",
              height: 142,
              mt: 2,
            }}
            image="/images/logo.png"
            title="green iguana"
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography component="h1" variant="h6">
              Reset Password
            </Typography>
            <FormikProvider value={formik}>
              <Box
                component="form"
                noValidate
                onSubmit={formik.handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched["password"] && formik.errors["password"]
                  }
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                />
                <ErrorMessage name="password" component={StyledFormicError} />
                <TextField
                  variant="standard"
                  onChange={formik.handleChange}
                  margin="normal"
                  required
                  fullWidth
                  name="repeatPassword"
                  label="Repeat password"
                  type="password"
                  id="repeatPassword"
                />
                <ErrorMessage
                  name="repeatPassword"
                  component={StyledFormicError}
                />
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    disabled={loading}
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>

                <Grid container>
                  {error && (
                    <StyledFormicError>
                      Invalid code provided, please start the reset password
                      process again.
                    </StyledFormicError>
                  )}
                </Grid>
              </Box>
            </FormikProvider>
          </CardContent>
        </Card>
      </Grid>
      <Notification onClose={() => {}} />
    </ThemeProvider>
  );
};

export default ResetPasswordWrapper;
